.dashboard-card-skeleton {
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-radius: 12px;
  padding: 25px 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 1440px) {
    padding: 25px;
    justify-content: flex-start;
  }
  @media (max-width: 1100px) {
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
