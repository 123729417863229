.layout-conatiner {
  @media (min-width: 1400px) and (max-width: 1800px) {
    zoom: var(--web-zoom);
  }
}

.layout-main {
  // padding-inline: var(--padding-x);
  // flex: 1; /* This is key to making the main content fill the remaining space */
  // margin-left: 250px;
  // overflow-x: hidden;
}
@media (max-width: 768px) {
  .layout-main {
    margin-left: 0;
    padding-inline: 10px;
  }
  .overlay {
    position: fixed; /* Cover the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 5; /* Below the sidebar */
  }
}
