.container {
  flex: 1;
  row-gap: 0.3rem;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    // margin-bottom: 1rem;
  }
}

.autocompleteLabel {
  font-size: var(--md-font-size) !important;
  color: black !important;
  font-family: "Poppins", sans-serif !important;
  position: static !important;
  transform: none !important;
  margin-bottom: 0.5rem !important;
}

.autocompleteInput {
  background: transparent !important;
  height: 100% !important;
  width: 100% !important;
  font-size: 0.875rem !important;
  box-sizing: border-box !important;
  color: inherit !important;
  border: 0.8px solid #d9dcdc !important;
  border-radius: 6px !important;
  padding-block: 0.7rem !important;
  margin-bottom: 2px !important;
  padding-left: 55px !important;

  &:focus {
    outline: none !important;
    border: 1px solid rgba(0, 122, 255, 1) !important;
  }
}

.listboxItem {
  font-size: 0.875rem !important;
}

.autocomplete-error {
  background: transparent !important;
  height: 100% !important;
  width: 100% !important;
  font-size: 0.875rem !important;
  box-sizing: border-box !important;
  color: inherit !important;
  border: 0.8px solid #fb5b5b !important;
  border-radius: 6px !important;
  padding-block: 0.7rem !important;
  margin-bottom: 2px !important;
  &:focus,
  &:focus-visible,
  &:focus-within {
    border: 1px solid #fb5b5b;
  }
}
