.sort-by {
  display: flex;
  flex-direction: column-reverse;
  // width: 7rem;
  height: auto;
  z-index: 2;
  border-radius: 0.5rem;
  // position: relative;
  // overflow: hidden;
  // margin-inline-end: 0.8rem;
  // padding-top: -20px;
  // padding-bottom: 1rem;
  align-items: center;
  justify-content: flex-end;
  //   &-btn {
  //     cursor: pointer;
  //     border: 1px solid rgba(202, 202, 202, 0.5);
  //     border-radius: 0.5rem;
  //     background: none;
  //     display: flex;
  //     width: 6rem;
  //     height: 3rem;
  //     align-items: center;
  //     justify-content: space-evenly;
  //   }
  //   &-drop-down {
  //     display: flex;
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     z-index: 3;
  //     flex-direction: column;
  //     width: 12.375rem;
  //     height: 11.75rem;

  //     border: 1px solid rgba(202, 202, 202, 0.5);
  //     border-radius: 0.5rem;
  //   }
}
.sort {
  display: flex;
  // flex-direction: column-reverse;
  // width: 7rem;
  height: auto;
  z-index: 2;
  border-radius: 0.5rem;
  // position: relative;
  // overflow: hidden;
  // margin-inline-end: 0.8rem;
  // padding-top: -20px;
  // padding-bottom: 1rem;
  align-items: center;
  justify-content: flex-end;
}
