.origin-port {
  width: 100%;
  max-width: 400px;
  min-width: 400px;

  @media (max-width: 1450px) {
    max-width: none;
  }
}
.auto-complete {
  width: 100%;

  background: rgba(255, 255, 255, 0.17) !important;
  border-radius: 10px !important;
  border: 0.8px solid #cacacab2 !important;
  overflow: hidden;
  & > div {
    width: 100%;
    border-radius: 0.5rem;
    border: none;
    & * {
      border: none;
    }
  }
  svg {
    color: #888;
  }
  .auto-complete .MuiOutlinedInput-root {
    border: 10px solid red; /* Set initial border */
    &:hover,
    &:focus {
      border-color: white;
    }
  }

  &-origin {
    display: flex;
    place-items: center;
    border: 0.8px;
    gap: 8px;
    color: white;
    &:active {
      border-color: white;
    }
  }
}
.labelView {
  margin-bottom: 12px;
  &-label {
    color: #0d0e0e;
    min-width: max-content;
    font-weight: 500;
    line-height: 23px;
  }
}
.label-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 5%;
  gap: 2.5px;

  img {
    padding-right: 7px;
  }
}
@media screen and (max-width: 768px) {
  .origin-port {
    min-width: unset;
    height: auto;
  }
  .label-icon {
    padding-inline: 4%;
  }
}
