.containers {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-top: 1rem;
}
.progress-container {
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background-color: #d9d9d9;

  > div {
    transition: all ease-in-out 0.3s;
    height: 100%;
    border-radius: 8px;
    background-color: #157ffb;
  }
}
.validations-container {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.validation {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  &-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.normal {
  font-size: var(--sm-font-size);
  color: #808080;
  font-weight: 500;
}

.success {
  font-size: var(--sm-font-size);
  color: #13a007;
  font-weight: 500;
}

.error {
  font-size: var(--sm-font-size);
  color: #71838e;
  font-weight: 500;
}

.error-message {
  display: none;
}
