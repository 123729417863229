.profile {
  padding-inline: var(--padding-x);
  padding-top: calc(1.2rem + 95px);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background-color: #fafafa;
  min-height: 90vh;
  padding-bottom: 2rem;

  &-title {
    margin-bottom: 0rem;
    @media screen and (width <= 768px) {
      display: none;
    }
  }

  > section {
    padding: 1.25rem 1.5rem;
    background-color: white;
    border: 1px solid #ededed;
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    position: relative;
    max-width: 991px;

    > button {
      position: absolute;
      top: 1rem;
      inset-inline-end: 1rem;
    }

    .image-row {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      &-image-container {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 999px;

        > img {
          width: 100%;
          height: 100%;
          border-radius: 999px;
          object-fit: cover;
          max-width: 96px;
          max-height: 96px;
        }

        .edit-icon {
          width: 35px;
          height: 35px;
          position: absolute;
          top: 70%;
          right: 0%;
          cursor: pointer;
          background-color: #fff;
          border-radius: 50%;
        }

        .dropDownOptions {
          background-color: #fff;
          box-shadow: 0px 10px 20px 0px #50505033;
          position: absolute;
          top: 105%;
          left: 67%;
          padding: 15px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 500;
          min-width: max-content;
          // min-height: 132px;
          z-index: 1;

          .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 10px 15px;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover {
              background-color: #f8f8f8;
            }
          }

          a {
            color: #546169;
          }
        }

        > button {
          position: absolute;
          bottom: -0.5rem;
          inset-inline-end: -0.5rem;
        }
      }

      > h3 {
        font-size: 1.25rem;
        font-weight: 500;
        color: #1b2029;
      }
    }

    .data {
      > label {
        font-size: 1.1rem;
        color: #1b2029;
      }
      > p {
        font-size: var(--md-font-size);
        color: #717171;
      }
    }
  }

  > form {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    .field {
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-width: 720px;

      @media screen and (max-width: 768px) {
        max-width: none;
      }

      p {
        text-transform: capitalize;
      }
    }
    .validations {
      max-width: 720px;
      @media screen and (max-width: 768px) {
        max-width: none;
      }
    }

    .note {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      max-width: 720px;
    }

    // .image-container {
    //   position: relative;
    //   width: 100px;
    //   height: 100px;
    //   border-radius: 999px;

    //   > img {
    //     width: 100%;
    //     height: 100%;
    //     border-radius: 999px;
    //     object-fit: cover;
    //   }

    //   > button {
    //     position: absolute;
    //     bottom: -0.5rem;
    //     inset-inline-end: -0.5rem;
    //   }
    // }

    .buttons-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 0.5rem;
      margin-top: 2rem;
      max-width: 720px;

      @media screen and (max-width: 768px) {
        max-width: none;
      }

      > button {
        width: 15%;

        &:first-child {
          background-color: transparent !important;
          color: #bd3c31;
        }
      }
    }
  }
}
@media screen and (max-width: 2600px) {
  .profile {
    padding-inline: 25rem 3rem;
  }
}
@media screen and (max-width: 1024px) {
  .profile {
    padding-inline: 24rem 2rem;
  }
}
@media screen and (max-width: 768px) {
  .profile {
    padding-inline: 1rem;
  }
}

.saveBtn {
  min-width: 227px !important;
}
