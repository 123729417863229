.customDialogPaper {
  width: 50vw !important; /* Full width of the viewport */
  height: auto !important; /* 70% of the viewport height */
  //   position: absolute !important; /* Fix position to ensure it covers the specified area */
  //   bottom: 0; /* Align the modal to the bottom */
  //   left: 0;
  margin: 0 !important; /* Remove default margins */
  border-radius: 1rem !important; /* Optional: Remove border radius if you want the modal to cover the entire width */

  @media screen and (max-width: 1400px) {
    width: 60vw !important;
  }
  @media screen and (max-width: 1280px) {
    width: 70vw !important;
  }
  @media screen and (max-width: 992px) {
    width: 90vw !important;
  }
  @media screen and (max-width: 768px) {
    width: 100vw !important;
    height: 100vh !important;
    max-height: 100vh !important;
    border-radius: 0 !important;
  }
  .titleBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: ;
    width: 100%;
    padding: 1rem !important;
  }

  .closeButton {
    padding: 0; /* Optional: Customize padding */
  }
}

.customDialogRoot {
  padding: 0 !important; /* Optional: Remove any additional padding */
}
