@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

:root {
  --padding-x: 22rem;
  font-size: var(--md-font-size);
  --green-300: #55c3b7;
  --white-80: #ffffffd9;
  --white-0: #ffffff;
  --black-700: #0c2544;
  --black-400: #333333;
  --blue-800: #091e37;
  --blue-900: #002d4a;
  --blue-background: #06071b;
  --blue-footer: #06132d;
  --gray-200: #e3e3e3;
  --gray-gradient: linear-gradient(180deg, #e8f4fa 0%, #e7ebee 100%);
  /* --xxxxl-font-size: 4rem; */
  /* --xxxl-font-size: 3rem; */
  /* --xxl-font-size: 2.5rem; */
  --xl-font-size: calc(2rem + 2px);
  --lg-font-size: calc(1.5rem + 2px);
  --md-font-size: calc(1rem + 2px);
  --sm-font-size: calc(0.8rem + 2px);
  --xs-font-size: calc(0.65rem + 2px);
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

a {
  text-decoration: none;
  color: currentColor;
  cursor: pointer;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
}
main {
  /* zoom: 80%; */
}
header {
  /* zoom: 80%; */
}
aside {
  /* zoom: 80%; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 100%;
  padding-inline: 1%;
  margin-inline: auto;
}

.loader-container {
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;
}

.loader {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.no-padding {
  --padding-x: 0;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 0.5rem solid #f7a920;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@media screen and (max-width: 3840px) {
  :root {
    font-size: calc(18px + 2px);
  }
}
@media screen and (max-width: 2200px) {
  :root {
    font-size: calc(18px + 2px);
  }
}
@media screen and (max-width: 1920px) {
  :root {
    font-size: var(--md-font-size);
  }
}
@media screen and (max-width: 1600px) {
  :root {
    font-size: calc(14px + 2px);
  }
}
@media screen and (max-width: 1400px) {
  :root {
    font-size: calc(14px + 2px);
  }
}
@media (max-width: 1200px) {
  :root {
    font-size: calc(12px + 3px);
    --layout-padding: 3rem 5rem 10rem 5rem;
  }
}
@media (max-width: 992px) {
  :root {
    font-size: calc(12px + 2px);
  }
}
@media (max-width: 820px) {
  :root {
    font-size: calc(11.5px + 2px);
  }
}
@media (max-width: 768px) {
  :root {
    font-size: calc(14px + 2px);
    --layout-padding: 3rem;
  }
}

.page-title {
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 3rem;
}

.react-pdf__Page__annotations {
  display: none;
}

.react-tel-input .selected-flag {
  padding: 0 !important;
}
.css-1k5tflg-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  min-height: 3.3rem !important;
}
@media screen and (max-width: 1920px) {
  .css-1k5tflg-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    min-height: 3.6rem !important;
  }
}
@media screen and (max-width: 1600px) {
  .css-1k5tflg-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    min-height: 3.9rem !important;
  }
}
@media screen and (max-width: 1400px) {
  .css-1k5tflg-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    min-height: 4.3rem !important;
  }
}
.css-knqc4i-MuiDialogActions-root {
  gap: 2rem;
  padding: 0 24px 16px 24px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 1rem !important;
  @media screen and (max-width: 768px) {
    margin: 0 !important;
    border-radius: 0 !important;
    width: 80% !important;
    max-width: 100% !important;
    position: fixed !important;
    bottom: 50% !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
}
.dateInput_date-input__AR4AG.dateInput_date-input__AR4AG input:focus {
  color: black !important;
}
.css-csmqbv-MuiPaper-root-MuiDialog-paper {
  margin: 0 !important;
  width: 100% !important;
  /* align-items: flex-end !important; */
  justify-self: flex-end !important;
}
/* .css-csmqbv-MuiPaper-root-MuiDialog-paper {
  max-height: 100% !important;
} */

.Toastify__toast-container--top-center {
  @media screen and (max-width: 480px) {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
  }
}

.react-pdf__Page__canvas {
  width: 100% !important;
}
