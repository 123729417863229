/* CustomModal.module.css */
/* Dialog.module.css */
.customDialogPaper {
  width: 100% !important; /* Full width of the viewport */
  // height: 70vh !important; /* 70% of the viewport height */
  position: fixed !important; /* Fix position to ensure it covers the specified area */
  bottom: 0; /* Align the modal to the bottom */
  left: 0;
  margin: 0 !important; /* Remove default margins */
  border-radius: 15px 15px 0 0 !important;
}

.customDialogRoot {
  padding: 0; /* Optional: Remove any additional padding */
}

.modalContainer {
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  width: 100%;
  padding: 0;
}

.titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 16px;
  padding-inline: 16px;
  // width: 90vw;
}

.closeButton {
  padding: 0; /* Optional: Customize padding */
}
.filtersSidebar {
  //   margin-left: 10px;
  padding-inline: 16px;
  h2 {
    font-size: 28px;
    margin-bottom: 16px;
    color: #1b2029;
  }
  .filters {
    background-color: #fff;
    // border: 1px solid #f2f2f2;
    border-radius: 20px;
  }
  .filterButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
  .filter {
    border-bottom: 1px solid #f4f1f1;
    margin-bottom: 20px;
    h3 {
      font-size: 19px;
      margin-bottom: 10px;
      color: #1b2029;
    }
    .inputs {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 10px;
    }
    .hasSwitch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 10px;
    }
    .checkboxs {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
