.request-quote-form-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.3rem;

  > button {
    font-size: 1.2rem !important;
  }

  &-back {
    column-gap: 0.5rem;
    color: #546169 !important;
    background-color: transparent !important;
    padding-inline: 0 !important;

    &:hover {
      box-shadow: none !important;
    }
  }

  &-next {
    width: 25%;
    min-width: 227px !important;
    padding-block: 0.8rem;

    @media screen and (width < 1024px) {
      min-width: max-content !important;
    }
  }
}

.back-disabled {
  opacity: 0.5;
}
