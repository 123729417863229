.container {
  display: flex;
  width: 100%;
  column-gap: 0.625rem;
  justify-content: center;
  .otp-input {
    border: none;
    background-color: #f2f2f280;
    border-radius: 6px;
    text-align: center;
    font-size: var(--md-font-size);
    font-weight: bold;
    line-height: 1;
    width: 3rem;
    height: 3rem;
    &:focus {
      border: rgba(189, 60, 49, 1) solid 1px;
      outline: none;
    }
  }
  .otp-error {
    border: 1px solid #fb5b5b;
    &:focus {
      border: 1px solid #fb5b5b;
    }
  }

  ::placeholder {
    color: #808080cc;
  }

  :-ms-input-placeholder {
    color: #808080cc;
  }

  ::-ms-input-placeholder {
    color: #808080cc;
  }
}
.error {
  font-size: 0.875rem;
  align-self: center;
  color: #fb5b5b;
  // transform: translateX(-75px);
}
