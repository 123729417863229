.filtersSidebar {
  // width: 30%;

  @media (max-width: 1300px) {
    width: 100%;
  }
  h2 {
    font-size: 28px;
    margin-bottom: 35px;
    color: #1b2029;
  }
  .filters {
    padding: 40px 20px;
    background-color: #fff;
    border: 1px solid #d9dcdc;
    border-radius: 16px;
  }
  .filterButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
  .filter {
    border-bottom: 1px solid #f4f1f1;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
    h3 {
      font-size: 19px;
      margin-bottom: 10px;
      color: #1b2029;
    }
    .inputs {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 10px;
    }
    .hasSwitch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 10px;
    }
    .checkboxs {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.date-height {
  height: 100%;
}
