.search {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  // height: 143px;
  gap: 28px;
  padding: 28px;
  background: #f2f3f3;
  border: 0.5px solid rgba(255, 255, 255, 1);
  border-radius: 1rem;
  width: 100%;
  max-width: max-content;

  @media (max-width: 1920px) {
    gap: 20px;
  }

  .date-search-container {
    display: flex;
    align-items: flex-end;
    gap: 14px;
    max-width: 400px;
    min-width: 400px;

    @media (max-width: 1920px) {
      max-width: 300px;
      min-width: 300px;
    }

    @media (max-width: 1400px) {
      max-width: none;
      min-width: unset;
      width: 100%;
    }

    @media (max-width: 768px) {
      min-width: unset;
    }

    // @media (max-width: 1450px) {
    //   width: 100%;
    // }
  }

  &-action {
    all: unset;
    cursor: pointer;
    display: grid;
    place-items: center;
    background: #bd3c31;
    margin-top: 30px;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  &-action-pressed {
    all: unset;
    cursor: pointer;
    display: grid;
    place-items: center;
    background: #bd3c31;
    margin-top: 30px;
    padding: 1rem;
    border-radius: 0.5rem;
    opacity: 0.6;
    cursor: not-allowed;
  }
  &-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    padding-bottom: 13px;

    @media (max-width: 1300px) {
      max-width: 400px;
      min-width: 400px;
      padding-bottom: 0;
      justify-content: flex-start;

      img {
        transform: rotate(90deg);
      }
    }
  }
}
@media (max-width: 768px) {
  .search {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px 12px;
    gap: 0.5rem;
    width: 94vw;
    justify-content: center !important;
    align-items: flex-start;
    border-radius: 10px;
    max-width: unset;
    &-arrow {
      display: none;
    }
    // &-action {
    //   &-container {
    //     width: 100%;
    //     display: flex;
    //     justify-content: center;
    //   }
    //   width: 95.5%;
    //   max-width: unset;
    //   // background-color: white;
    //   height: 25px;
    //   p {
    //     font-size: 1rem;
    //     color: #fff;
    //     font-weight: 600;
    //   }
    // }
    // &-action-pressed {
    //   width: 95.5%;
    //   p {
    //     color: #bd3c31;
    //   }
    // }
    // &-action:disabled {
    //   opacity: 0.5;
    // }
    // &-img {
    //   display: none;
    // }
  }
}

.cargo-styles {
  flex: auto;
  gap: 12px;
  max-width: 400px;
  min-width: 400px;

  // @media (max-width: 1450px) {
  //   max-width: none;
  // }

  @media (max-width: 768px) {
    min-width: unset;
  }
}
.containersStyles {
  min-width: 300px;
  max-width: 300px;
  @media (max-width: 1400px) {
    max-width: none;
    min-width: unset;
  }
}

.placeholder-color {
  color: #808b8c;
  opacity: 0.5;
}

// .search-date {
//   max-width: 400px;
//   min-width: 400px;
//   row-gap: 12px;

//   @media (max-width: 1450px) {
//     max-width: none;
//     min-width: unset;
//   }

//   @media (max-width: 768px) {
//     min-width: unset;
//   }
// }

.date-label {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(13, 14, 14);
}

.input-container-styles {
  width: 100%;
  max-width: 400px;
  min-width: 400px;
  min-height: 1.4375em;
  height: 100% !important;
  border-radius: 10px !important;

  @media (max-width: 1920px) {
    max-width: 300px;
    min-width: 300px;
  }
  @media (max-width: 1400px) {
    max-width: none;
  }
}

.input-styles {
  height: auto !important;
  min-height: 1.4375em;
  margin-bottom: 0 !important;
  padding: 16.5px 14px 16.5px 50.5px !important;
}
.label-styles {
  margin-bottom: 8px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: rgb(13, 14, 14) !important;
}
