.sales-requests-table {
  overflow-x: auto;
  width: 100%;
  margin-top: 2rem;
  min-height: 100%;
  padding-bottom: 8rem;
  border-radius: 1rem 1rem 0 0;
}
.table {
  width: 100%;
  min-width: 1450px;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 1rem 1rem 0 0;
  overflow: auto;
  font-family: Arial, sans-serif;
  text-align: left;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  .filterContainer {
    position: relative;
    &-dropdownMenu {
      position: absolute;
      top: 3rem;
      right: 0;
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      width: 180px;
      z-index: 10;
    }
    &-dropdownItem {
      padding: 12px;
      font-size: 14px;
      cursor: pointer;
      color: #333;
      font-weight: 400;

      &:hover {
        background-color: #f5f5f5;
      }

      &:first-of-type {
        // border-bottom: 1px solid #e0e0e0;
      }
    }
  }
  .filterButton {
    background: none;
    border: none;
    cursor: pointer;
  }
}
thead tr th {
  font-weight: 400;
  min-width: max-content;
  border-bottom: 1px solid #ddd;
}
th,
td {
  padding: 1rem 0.5rem;
}

th {
  background-color: #f8f9fa;
  font-weight: bold;
}

tbody tr {
  height: 75px;
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: transparent !important;
  }
}
tbody tr td {
  color: #667085;
  min-width: max-content;
}
tbody tr:hover {
  background-color: #f4fbff;
}

td svg {
  width: 24px;
  height: 24px;
}

.status {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 35px;
  border-radius: 23.47px;
  background-color: #e6f7ff;
  color: #1890ff;
}

.new {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 33px;
  border-radius: 23.47px;
  background-color: #f3e5f5;
  color: #9c27b0;
}

.expired {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 35px;
  border-radius: 23.47px;
  background-color: #e0e0e0;
  color: #9e9e9e;
}

.accepted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 35px;
  border-radius: 23.47px;
  background-color: #e8f5e9;
  color: #4caf50;
}

.pending {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 35px;
  border-radius: 23.47px;
  background-color: #fff8e1;
  color: #ffb300;
}
.ellipsis {
  text-align: center;
  color: #ccc;
  font-size: 18px;
  cursor: pointer;
  padding: 8px;

  &:hover {
    color: #000;
  }
}
.filterContainer {
  position: relative;
}
.filterButton {
  background: none;
  border: none;
  cursor: pointer;

  .dropdownMenu {
    position: absolute;
    top: 3rem;
    right: 3rem;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 180px;
    z-index: 10;

    .dropdownItem {
      padding: 12px;
      font-size: 14px;
      cursor: pointer;
      color: #333;
      font-weight: 400;

      &:hover {
        background-color: #f5f5f5;
      }

      &:first-of-type {
        // border-bottom: 1px solid #e0e0e0;
      }
    }
  }
}
.route {
  min-width: max-content;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: center;
  // gap: 5px;
  p {
    min-width: max-content;
  }
}
.port {
  font-size: var(--sm-font-size);
  font-weight: 400;
  color: #000;
}
.country {
  font-size: var(--xs-font-size);
  font-weight: 400;
}

.unseen-row {
  background-color: #f4fbff;
  &:hover {
    background-color: #f4fbff !important;
  }
}
