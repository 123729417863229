.forget-password {
  &-title {
    display: flex;
    font-weight: 500;
    font-size: 1.75rem;
    // color: rgba(189, 60, 49, 1);
    justify-self: flex-start;
    align-self: flex-start;
    padding-block: 0.5rem 1rem;
  }
  &-subtitle {
    font-weight: 400;
    font-size: var(--md-font-size);
    color: rgba(152, 162, 179, 1);
    padding-bottom: 3rem;
  }
}
// @media (max-width: 768px) {
//   .forget-password {
//     &-container {
//       height: 100%;
//       justify-content: space-between;
//       padding: 2rem;
//     }
//     &-form {
//       height: 100rem;
//       justify-content: space-between;
//     }
//   }

// }
