.dashboard {
  padding-inline: 50px;
  padding-top: calc(95px + 65px);
  padding-bottom: 95px;
  margin-left: var(--sidebar-width);
  display: flex;
  flex-direction: column;
  gap: 48px;
  background: #fafafa;
  height: 100%;
  min-height: 100vh;

  &-row {
    display: flex;
    flex-direction: column;
    gap: 18px;

    &-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h3 {
        font-size: calc(var(--md-font-size) + 5px);
        color: #262a2a;
        line-height: 33px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        span {
          color: #98a2b3;
          font-size: calc(var(--sm-font-size) + 5px);
          font-weight: 400;
          line-height: 28px;
        }
      }
      a {
        font-weight: 500;
        color: #157ffb;
        font-size: 16px;
      }
    }

    &-cards {
      display: flex;
      gap: 18px;
      flex-wrap: wrap;
    }
    .unique-card {
      background: linear-gradient(98.08deg, #bd3c31 -12.97%, #1c1c1c 115.76%);
      border-radius: 12px;
      padding: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 18px;
      min-width: 495px;

      @media (max-width: 1100px) {
        width: 100%;
        min-width: unset;
      }

      p {
        text-align: center;
        font-weight: 400;
        color: #fff;
        line-height: 30px;
        font-size: var(--md-font-size);
        // max-width: 340px;
      }
      button {
        background-color: #fff;
        border-radius: 6px;
        padding: 8px 32px;
        color: #1c1c1c;
        font-weight: 500;
        line-height: 20px;
        font-size: 16px;
        border: none;
        cursor: pointer;
      }
    }

    .map-request {
      display: grid;
      grid-template-columns: 1fr 2fr;
      // display: flex;
      gap: 18px;

      .map-img {
        max-width: 700px;
        height: 100%;

        @media screen and (max-width: 1400px) {
          max-width: none;
          width: 100%;
        }
      }
      @media screen and (max-width: 1400px) {
        grid-template-columns: 1fr;
      }
    }
  }
}

// Responsive Breakpoints.
@media screen and (max-width: 768px) {
  .dashboard {
    padding-inline: 2px;
    padding-top: calc(95px + 20px);
    padding-bottom: 20px;
    margin-left: 0;
  }
}
