.table-container {
  width: 100%;
  overflow-x: auto;

  .shipment-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    min-width: 1200px;

    th,
    td {
      padding: 16px;
      white-space: nowrap;
    }

    th {
      font-weight: 400;
      color: #0d0e0e;
      font-size: 17px;
      border-bottom: 1.5px solid #eaecf0;
    }

    tr {
      border-bottom: 1.5px solid #eaecf0;
      background: #fff;
      &:hover {
        background-color: #fff;
      }
    }

    td {
      color: #667085;
      font-size: 16px;

      .port-text {
        color: #4d5354;
        font-size: 16px;
      }

      img {
        vertical-align: middle;
      }

      &:last-of-type {
        img {
          margin-right: 10px;
        }
      }
    }
    .route-cell-container {
      max-width: 300px;
    }
    .route-cell {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      gap: 1rem;
      max-width: 300px;

      &-item {
        min-width: 200px;
      }
    }

    .country-text {
      font-size: 14px;
      color: #667085;
    }
  }
}
