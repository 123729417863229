.footer {
  margin-left: 250px;
  background-color: #131313;
  padding-block: 30px;
  .logo {
    max-width: 55px;
    max-height: 55px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .linksWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .links {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .socialIcons {
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: flex-end;
    list-style: none;
    text-decoration: none;
    margin-top: 22px;
    a {
      border: 1px solid #fff;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 19px;
        max-height: 17px;
        object-fit: contain;
      }
    }
  }
}

.copyRights {
  background-color: #fff;
  padding-block: 10px;
  p {
    text-align: center;
  }
}
