.paginationContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-top: 1rem;
  // min-width: 1000px;
}
.paginationContainerClient {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-top: 1rem;
}

.arrowButton {
  border: 1px solid #e0e0e0;
  background-color: white;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-size: 18px;
  color: #333;
  transition: background-color 0.3s ease;
  border-radius: 50%;
  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    color: #999;
    cursor: not-allowed;
    background-color: #fafafa;
  }
}

.pageButton {
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: white;
  padding: 5px 10px;
  cursor: pointer;
  font-size: var(--md-font-size);
  color: #333;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
}

.active {
  background-color: #0057ff;
  color: white;
}

.pageInfo {
  position: absolute;
  right: 0%;
  justify-self: end;
  margin-left: 20px;
  font-size: 14px;
  color: #666;
}
@media (max-width: 1040px) {
  .pageInfo {
    right: 50%;
    top: 100%;
    margin-top: 1rem;
    transform: translateX(50%);
    width: 100%;
    text-align: center;
    padding-bottom: 1rem;
  }
}
