.actions {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  // padding-inline-end: 1rem;

  &-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1rem;

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      row-gap: 1rem;
      justify-content: flex-end;
    }
  }
  &-filters {
    // display: flex;
    // align-items: flex-end;
    // width: 100%;
    // min-width: max-content;
    // gap: 2rem;
    // justify-content: flex-start;
    // margin-top: 1rem;
    // &-date-input-field {
    //   max-width: 500px !important ;
    //   background-color: #333;
    // }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    width: 100%;

    @media screen and (max-width: 1400px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
    }

    .actions-filters-buttons {
      display: flex;
      gap: 1rem;
      align-items: center;
      @media screen and (max-width: 650px) {
        justify-content: flex-end;
      }
    }
  }
}

.sortDropdown {
  position: relative;
  display: inline-block;
  width: 6rem;
  margin-inline-end: 4rem;
  span {
    min-width: max-content;
    color: #157ffb;
  }
  &-filterButton {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 12px;
    border-radius: 40px;
    cursor: pointer;
    font-size: var(--md-font-size);
    margin-inline-end: 1rem;
    min-width: max-content;
    justify-content: center;
    color: #157ffb;
    height: 48px;

    &:hover {
      background-color: #f5f5f5;
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  .dropdownMenu {
    position: absolute;
    top: 48px;
    left: -44px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 180px;
    z-index: 10;

    .dropdownItem {
      padding: 12px;
      font-size: 14px;
      cursor: pointer;
      color: #333;
      font-weight: 400;

      &:hover {
        background-color: #f5f5f5;
      }

      &:first-of-type {
        // border-bottom: 1px solid #e0e0e0;
      }
    }
  }
}
.tire {
  width: 300px !important;
}

.dateInput {
  max-width: 500px !important ;
  min-width: 290px !important ;

  @media screen and (max-width: 650px) {
    max-width: unset !important ;
  }
}
.viewInput {
  max-width: 500px !important ;
  min-width: 290px !important ;
  gap: 0.3rem;

  @media screen and (max-width: 650px) {
    max-width: unset !important ;
  }
}
.addButton {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-left: 1rem;
}

.actions-input {
  border-radius: 6px !important;
  @media screen and (max-width: 1920px) {
    min-height: 51px !important;
  }
  @media screen and (max-width: 1600px) {
    min-height: 51px !important;
  }
  @media screen and (max-width: 1400px) {
    min-height: 51px !important;
  }
}
