.info-window {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &-header {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #1a202c;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
  }

  &-content {
    color: #8f8f8f;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    color: #8f8f8f;
    font-size: 14px;
    font-weight: 400;

    & .date-actual,
    & .date-notActual {
      color: #11734b;
      font-weight: 400;
      font-size: 14px;
    }
    & .date-notActual {
      color: #157ffb;
    }
  }

  &-containerInfo {
    color: #8f8f8f;
    font-size: 14px;
    font-weight: 400;
  }
}
