/* CustomModal.module.css */
/* Dialog.module.css */
.customDialogPaper {
  width: 100% !important; /* Full width of the viewport */
  height: auto !important; /* 70% of the viewport height */
  position: fixed !important; /* Fix position to ensure it covers the specified area */
  bottom: 0; /* Align the modal to the bottom */
  left: 0;
  margin: 0 !important; /* Remove default margins */
  border-radius: 15px 15px 0 0 !important; /* Optional: Remove border radius if you want the modal to cover the entire width */
}

.customDialogRoot {
  padding: 0; /* Optional: Remove any additional padding */
}

.modalContainer {
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  width: 100%;
  padding: 0;
}

.titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 16px;
  // width: 90vw;
}

.closeButton {
  padding: 0; /* Optional: Customize padding */
}
/* FilterOptions.module.css */

.container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.radioItem {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  font-size: var(--md-font-size);
  color: #333333;
  width: 100%;
  justify-content: flex-start;
  gap: 0.5rem;
}

.radioInput {
  margin-right: 10px;
  transform: scale(2);
}

.radioLabel {
  font-size: 1.3rem;
  color: #333333;
  opacity: 0.8;
}
