.signup {
  &-container {
    gap: 2rem;
  }
  &-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  &-subtitle {
    font-weight: 400;
    font-size: var(--md-font-size);
    color: rgba(152, 162, 179, 1);
    // padding-bottom: 1rem;
  }
  &-title {
    font-weight: 500;
    font-size: var(--md-font-size);
    color: rgba(189, 60, 49, 1);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    // margin-top: 1rem;
  }
  &-header {
    width: 100%;
    // height: 17%;
    // margin-bottom: 2rem;
  }
  &-header-subtitle {
    // font-family: Poppins;
    margin-top: 1rem;
    font-size: var(--xl-font-size);
    font-weight: 500;
  }
  &-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    div {
      display: flex;
      flex-direction: column;
    }
  }
}
.tabs {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  justify-content: center;
}
.sign-up {
  text-align: center;
  color: rgba(152, 162, 179, 1) !important;
  font-weight: 400;
  font-size: var(--md-font-size);
  > span {
    color: rgba(189, 60, 49, 1);
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .signup {
    &-container {
      // height: 100%;
      // justify-content: space-between;
      gap: 2rem;
    }
    &-form {
      height: 100%;
      justify-content: space-between;
    }
  }
  .forget-password {
    display: flex;
    align-self: flex-end;
  }
}

.custom-bg {
  background-image: url(../../assets/registerBg.png);
  @media screen and (max-width: 768px) {
    background-image: none;
  }
}
