.modal {
  border-radius: 2rem !important;

  &-title {
    margin-top: 3rem !important;
  }
  &-actions {
  }
  &-confirm {
    min-width: 5vw;
    width: 45% !important;
  }
  &-cancel {
    width: 45%;
    border: 1px #e74c3c solid !important;
    border-radius: 10px !important;
    margin-block: 3rem !important;

    height: 2.7rem;
  }
}
