.container {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  border-bottom: 2px solid #f4f4f4;
  padding-bottom: 1rem;

  @media screen and (max-width: 768px) {
    justify-content: space-between;
    column-gap: 0;
  }

  &-tab-container {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding-inline: 0.5rem;

    .tabs-icon-container {
      display: flex;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    > h6 {
      font-size: var(--md-font-size);
      color: #546169;
      font-weight: 400;
    }

    &-count {
      background-color: #157ffb;
      border-radius: 999px;
      min-width: 24px;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      > p {
        font-size: 0.9rem;
        color: #fff;
        text-align: center;
      }
    }
  }

  &-tab-active {
    position: relative;
    > h6 {
      font-size: var(--md-font-size);
      color: #157ffb;
      font-weight: 500;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -19px;
      left: 0;
      right: 0;
      height: 4px;
      border-radius: 4px;
      background-color: #157ffb;
    }
  }
}
