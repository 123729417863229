.login {
  img {
    margin-bottom: 3rem;
  }
  &-p {
    margin: 0;
    color: rgba(102, 112, 133, 1);
    text-align: center;
    // margin-bottom: 3rem;
  }

  &-title {
    // margin-top: 1.5rem;
    font-weight: 500;
    font-size: 1.25rem;
    font-size: var(--xl-font-size);
    text-align: center;
  }
  &-btn {
    // max-width: 31.81rem;
    height: 3rem;
    width: 100% !important;
    // padding-inline: 3rem !important;
  }

  &-btn-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 768px) {
  .login {
    &-btn-container {
      height: 100%;
      width: 100%;
    }
    &-btn {
      width: 95.1%;
      height: 3rem;
    }
  }
}

.custom-bg {
  background-image: url(../../assets/registerBg.png);
  @media screen and (max-width: 768px) {
    background-image: none;
  }
}
