.slider-container {
  position: relative;

  &-arrows {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    z-index: 10;
    > button {
      all: unset;
      cursor: pointer;
    }
  }
}
