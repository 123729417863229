.requests {
  padding: 3rem 95px;
  padding-top: calc(2.5rem + 95px);
  margin-left: var(--sidebar-width);
  background-color: #fafafa;
  min-height: 100vh;
  &-title {
    font-size: 1.75rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 0.7rem;
    padding-block: 1.2rem;
  }
}
@media screen and (max-width: 1400px) {
  .requests {
    padding-inline: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .requests {
    padding-inline: 1rem;
    margin-left: 0;
    padding-top: calc(95px);
  }
}

.skeleton-styles {
  min-width: unset !important;
}

.empty-status {
  margin-top: 6rem;
}
