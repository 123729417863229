.dashboard-card {
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-radius: 12px;
  padding: 25px 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 1440px) {
    padding: 25px;
    justify-content: flex-start;
  }
  @media (max-width: 1100px) {
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  &-content {
    display: flex;
    flex-direction: column;
    min-width: 140px;

    h3 {
      color: #4d5354;
      font-weight: 600;
      font-size: calc(var(--lg-font-size) + 3px);
    }
    p {
      color: #808b8c;
      font-weight: 400;
      font-size: calc(var(--md-font-size));
    }
  }

  &-icon {
    background-color: #f8f6f6;
    border-radius: 50%;
    min-height: 114px;
    min-width: 114px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}
