.cargo-dropdown {
  gap: 8px;
  min-width: 400px !important;
  max-width: 400px !important;
  width: 100%;

  @media (max-width: 1920px) {
    min-width: 300px !important;
    max-width: 300px !important;
  }
  @media (max-width: 1400px) {
    max-width: none !important;
    min-width: unset !important;
  }

  .label {
    color: #0d0e0e;
    font-weight: 500;
    line-height: 23px;
    height: 100%;
  }
}
