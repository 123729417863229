.card {
  width: 100%;
  border: 1px solid #e2e3e3;
  border-radius: 1rem;
  background-color: white;

  &-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    &-data {
      display: flex;
      column-gap: 2rem;
      @media screen and (max-width: 768px) {
        display: none;
      }
      > p {
        color: #808080;
        max-width: 300px;
        word-break: break-all;
        > span {
          color: #4d5354;
          font-weight: 500;
        }
      }
    }

    &-bottom {
      border-bottom: 1px solid #e2e3e3;
    }
    &-top {
      border-top: 1px solid #e2e3e3;
      padding: 0.8rem 1.75rem;
      > button {
        min-width: fit-content;
        width: 15%;
        margin-inline-start: auto;
      }
    }

    > p {
      color: #4d5354;
      font-size: var(--md-font-size);
    }

    &-time {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;

      @media screen and (max-width: 768px) {
        display: none;
      }

      &-res {
        display: none;
        @media screen and (max-width: 768px) {
          display: flex;
        }
      }

      > p {
        color: #808b8c;
        font-size: var(--md-font-size);
      }
    }

    &-status {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;

      > p {
        width: 7rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.5rem;
        text-transform: capitalize;
        font-size: var(--md-font-size);
      }
      &-accepted {
        background-color: #e8f5e9;
        color: #4caf50;
      }
      &-rejected {
        background-color: #e0e0e0;
        color: #9e9e9e;
      }
      &-pending {
        background-color: #fff8e1;
        color: #ffb300;
      }
      &-active {
        background-color: #e6f7ff;
        color: #1890ff;
      }

      > button {
        padding: 0;
      }
    }
  }
  &-shipping-row {
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    gap: 32px;

    // @media screen and (max-width: 768px) {
    //   padding: 2rem 0.5rem;
    // }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 30px;
      // flex: 1;
      &-origin,
      &-destination {
        img {
          opacity: 0.6;
        }
      }
      &:first-of-type {
        align-items: flex-start;
      }
      &:last-of-type {
        align-items: flex-end;
      }

      @media screen and (max-width: 1024px) {
        &-origin,
        &-destination {
          display: none;
        }
        &-pol {
          align-items: flex-start;
        }
        &-pod {
          align-items: flex-end;
        }
      }
      img {
        object-fit: contain;
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 0.3rem;
      }
      &-strong {
        // margin-top: 0.3rem;
        font-weight: 600;
        min-width: max-content;
        color: #1a202c;
        text-wrap: nowrap;
        margin-bottom: 0.3rem;
      }
      &-country {
        // margin-bottom: 0.3rem;
        color: #808080;
        text-wrap: nowrap;
      }
      &-readiness {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        // margin-top: auto;
        > p {
          font-weight: 400;
          color: #808080;

          > span {
            font-weight: 500;
            color: #4d5354;
          }
        }
      }
    }
    &-separator-container {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      position: relative;

      &-origin,
      &-destination {
        & img {
          opacity: 0.6;
        }
      }

      @media screen and (max-width: 1024px) {
        &-origin,
        &-destination {
          display: none;
        }
      }

      & img {
        object-fit: contain;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
      }
    }
    &-separator {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
      padding-bottom: 1.5rem;
      background-image: linear-gradient(
        to right,
        #4d5354 60%,
        rgba(77, 83, 84, 0) 0%
      );
      background-position: 0% 98%;
      background-size: 15px 2px;
      background-repeat: repeat-x;
      opacity: 0.6;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    &-blue-separator {
      @media screen and (max-width: 1024px) {
        display: flex;
      }
      background-image: linear-gradient(
        to right,
        #157ffb 60%,
        rgba(21, 127, 251, 0) 0%
      );
      > p {
        color: #157ffb;
      }
    }
  }
  &-shipping-row-date {
    padding: 0rem 4rem 1rem 4rem;
  }
  &-collapse-res {
    display: none;
    padding: 0;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  &-collapse {
    padding: 0rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &-step {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      > h3 {
        font-size: var(--lg-font-size);
        font-weight: 500;
        color: #0d0e0e;
        @media screen and (max-width: 768px) {
          width: 100%;
          border-bottom: 0.6px solid #e3e3e3 !important;
          padding: 0 0 0.5rem 1.75rem;
        }
      }

      &-data {
        display: flex;
        align-items: flex-start;
        column-gap: 3rem;
        row-gap: 1rem;
        flex-wrap: wrap;

        @media screen and (max-width: 768px) {
          padding: 1rem 1.75rem;
          column-gap: 1.75rem;
          justify-content: space-between;
        }

        &-col {
          flex-direction: column;
          row-gap: 1rem;
          align-items: flex-start;
        }

        &-item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          &-label {
            font-size: 1.1rem;
            color: #71838e;
          }
          &-value {
            font-size: 1.1rem;
            color: #434b5a;
            font-weight: 500;
          }
        }
      }
      &-selected {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        @media screen and (max-width: 768px) {
          padding-inline-start: 5rem;
          padding-bottom: 1rem;
        }
        > p {
          color: #0d0e0e;
        }
      }
    }

    &-separator {
      height: 1px;
      width: 100%;
      background-color: #e9e9e9;
    }
  }
}

// Carousel edits for responsive view
.carouselArrows {
  inset-inline-end: 1.75rem;
}
@media screen and (max-width: 376px) {
  .card {
    &-row {
      &-time {
        margin-inline-end: 1rem;
      }
    }
  }
}
