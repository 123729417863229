.btn {
  font-size: var(--md-font-size) !important;
  font-weight: 500 !important;
  &.defaultBtn {
    height: auto;
    width: auto;
    min-width: max-content;
    padding-block: 0.5rem;
    padding-inline: 30px;
    border-radius: 10px;
    background-color: #bd3c31;
    text-transform: none;
    box-shadow: none;
    &:hover {
      background-color: #bd3c31;
    }
  }
}
