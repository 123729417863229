.otp-step {
  // padding-inline: 6.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  position: relative;
  width: 100%;
  &-logo {
    width: 50%;
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.5rem;
    &-title {
      font-size: var(--md-font-size);
      font-weight: 500;
      color: #000;
    }
    &-body {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25;
      color: #808080;
    }
  }
  &-input-container {
    margin-block-start: 3rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &-resend {
    all: unset;
    cursor: pointer;
    margin-inline-start: 0.5rem;
    text-decoration: none;
    color: #bfbfbf;
    &-text {
      font-size: 0.875rem;
      color: #696874;
      text-align: center;
      span {
        color: rgba(189, 60, 49, 1);
        font-weight: 700;
      }
      button {
        color: rgba(189, 60, 49, 1);
        font-weight: 700;
      }
    }
  }
  &-login-text {
    color: #808080;
    font-size: var(--md-font-size);
    text-align: center;
    a {
      font-size: var(--md-font-size);
      text-decoration: none;
      color: #de1a87;
      font-weight: 500;
    }
  }
  &-actions {
    margin-block-start: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    button {
      padding-block: 0.5rem;
    }
  }
}
