.containerEvents {
  margin-block: 36px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sortDropdown {
    position: relative;
    width: max-content;

    span {
      min-width: max-content;
      color: #1a202c;
      font-size: 16px;
      font-weight: 500;
    }
    &-filterButton {
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 8px 12px;
      border-radius: 40px;
      cursor: pointer;
      font-size: var(--md-font-size);
      min-width: max-content;
      justify-content: center;
      color: #157ffb;
      height: 48px;

      &:hover {
        background-color: #f5f5f5;
      }

      .icon {
        width: 16px;
        height: 16px;
      }
      .rotated {
        transform: rotate(180deg);
      }
    }

    .dropdownMenu {
      position: absolute;
      top: 48px;
      left: 0px;
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      width: 180px;
      z-index: 10;

      .dropdownItem {
        padding: 12px;
        font-size: 14px;
        cursor: pointer;
        color: #333;
        font-weight: 400;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  &-status {
    width: max-content;
    padding-inline: 10px;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.5rem;
    text-transform: capitalize;
    font-size: 16px;
  }

  .active {
    background-color: #e6f7ff;
    color: #1890ff;
  }

  .new {
    background-color: #f3e5f5;
    color: #9c27b0;
  }

  .expired {
    background-color: #e0e0e0;
    color: #9e9e9e;
  }

  .accepted {
    background-color: #e8f5e9;
    color: #4caf50;
  }

  &-events {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: 768px) {
      height: 200px;
      overflow-y: auto;
      padding-bottom: 1rem;
    }

    & .event-item {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;

      @media screen and (max-width: 1200px) {
        margin-left: 0;
      }
    }

    & .event-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      gap: 1.5rem;
      align-items: center;

      @media screen and (max-width: 920px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: 2fr 1fr 1fr;
      }
    }
    & .event-type {
      display: flex;
      gap: 8px;
      cursor: pointer;
      color: #1a202c;
      font-weight: 500;
      font-size: 16px;

      .icon {
        transform: rotate(-90deg);
      }

      p {
        text-transform: capitalize;
      }
    }
    & .event-location,
    & .event-date {
      color: #8f8f8f;
      font-weight: 400;
      font-size: 16px;
    }

    & .event-details {
      margin-top: 1rem;
      margin-left: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;

      &-column {
        display: flex;
        flex-direction: column;
        gap: 2px;

        p {
          color: #8f8f8f;
          font-weight: 400;
          font-size: 16px;
        }
        span {
          text-transform: capitalize;
        }
      }
    }
  }
}
