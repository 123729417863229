.customDialogRoot {
  width: 100vw; /* Optional: Remove any additional padding */
  margin: 0 !important;
}
.css-csmqbv-MuiPaper-root-MuiDialog-paper {
  margin: 0 !important;
}
.customDialogRoot {
  /* Additional modal styling */
}

.customDialogPaper {
  padding: 1rem;
  background-color: white;
}

.dragHandle {
  width: 40px;
  height: 4px;
  background-color: #ccc;
  border-radius: 2px;
  margin: 0.5rem auto;
  cursor: grab;
}
.modal {
  &-header {
    position: absolute;
    top: 10px;
    right: 1rem;
    // height: 15rem !important;
    width: max-content !important;
  }
}
.filterContainer {
  position: relative;
}
.filterButton {
  background: none;
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;

  .dropdownMenu {
    position: absolute;
    top: 3rem;
    right: 3rem;
    background-color: red;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 180px;
    z-index: 1000;

    .dropdownItem {
      padding: 12px;
      font-size: 14px;
      cursor: pointer;
      color: #333;
      font-weight: 400;

      &:hover {
        background-color: #f5f5f5;
      }

      &:first-of-type {
        // border-bottom: 1px solid #e0e0e0;
      }
    }
  }
}
.filterContainer {
  position: relative;
  &-dropdownMenu {
    position: absolute;
    top: 4rem;
    right: 95%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 180px;
    z-index: 10;
  }
  &-dropdownItem {
    padding: 12px;
    font-size: 14px;
    cursor: pointer;
    color: #333;
    font-weight: 400;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
