.card {
  width: 100%;
  background-color: #fff;
  //   padding-block: 1rem;
  border-radius: 16px;
  border: 1px solid #d9dcdc;
  height: auto;
  // margin-inline-end: 5.2vw;
  &-header {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d9dcdc;
    // padding-inline-end: 2rem;
    text-align: center;
    align-items: center;
    padding-inline: 2rem;
    gap: 32px;
  }
  &-content {
    // padding-inline: 2rem;

    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-etd {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 15px;
      margin-inline: 15px;
      p:first-child {
        opacity: 0.7;
      }
      p:nth-child(2) {
        font-size: large;
        font-weight: 600;
        min-width: max-content;
        opacity: 0.7;
      }
      img {
        width: 2rem;
        // aspect-ratio: 1;
        border: 1px solid #ccc;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-eta {
      width: 42vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      //   gap: 0.5rem;
      p {
        opacity: 0.9;
      }
      img {
        width: 2rem;
        aspect-ratio: 1;
        border: 1px solid #ccc;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-divider {
      position: relative;
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      width: 100%;
      height: 15vh;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      align-items: center;
      justify-content: center;
      p {
        // position: absolute;
        // inset-inline-start: 50%;
        // transform: translateX(-50%);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 1.75rem;
        font-size: large;
        opacity: 0.8;
        // inset-block-end: 1rem;
      }
      //   &::after {
      //     content: "";
      //     position: absolute;
      //     display: block;
      //     width: 0.75rem;
      //     height: 0.75rem;
      //     background-color: #bd3c31;
      //     border-radius: 50%;
      //     inset-block-end: 0;
      //     inset-inline-start: 50%;
      //     transform: translateX(-50%) translateY(50%);
      //     // box-shadow: 0 0 0 2px #fff, 0 0 0 4px #bd3c31;
      //   }
    }
  }
  &-footer {
    padding-inline: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.1rem;
    .shipping-line {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      img {
        max-width: 7rem;
      }

      p {
        font-weight: 600;
      }
    }
  }
  &-id {
    display: flex;
    overflow: hidden;
  }
  .img {
    width: 100%;
  }
  .country {
    display: flex;
    padding: 0.1 rem;
  }
  .quote {
    display: flex;
    flex-direction: column;
    gap: 16px;
    // height: 15vh;
    min-height: 3.25rem;
    justify-content: space-between;
    align-items: center;
    // padding-top: 1rem;
    p:nth-child(1) {
      opacity: 0.5;
      display: flex;
      flex-direction: column;
    }
    p:nth-child(2) {
      font-size: 1.85rem;
      font-weight: 500;
      width: 100%;
    }
    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .FishingHook {
    border: none;
    background: none;
    width: 1.56rem;
    height: 1.56rem;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
  }
  .leftside {
    width: 100%;
    height: 100%;
  }
  .left-top {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // margin-inline-start: 50px;
  }
}
@media (max-width: 768px) {
  .card {
    &-header {
      display: flex;
      flex-direction: column;
      padding-inline-end: unset;
      padding-inline: unset;
    }
    &-content {
      padding-inline: 0rem;
      // margin-left: 2rem;
      justify-content: center;
      width: 100vw;
      display: flex;
      &-eta {
        padding-inline: 0.5rem;
        width: 90vw;
        p {
          display: flex;
          // width: 5rem;
          overflow-x: hidden;
          text-wrap: wrap;
        }
      }
      // &-divider {
      //   // padding-top: 2rem;
      // }
    }
    &-footer {
      padding-inline: 1rem;
    }
    .country {
      display: flex;
      // padding: 0.2 rem;
      justify-content: space-between;
    }
  }
  .quote {
    display: flex;
    flex-direction: row !important;

    width: 100%;
    padding: 12px;
    border-top: 1px solid #ccc;

    .price {
      display: flex !important;
      align-items: flex-start !important;
    }
    p:nth-child(2) {
      font-size: var(--md-font-size) !important;
      font-weight: 500;
    }
  }
  .separator {
    // width: 92vw;
    display: flex;
    flex-direction: row;
    opacity: 0.5;
    height: 1;
  }
  .left-top {
    display: flex !important;
    width: 90vw !important;
  }
}

.card-shipping-row {
  padding: 2rem 0rem;
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;

  @media screen and (width <= 786px) {
    padding: 2rem 2rem 0rem 2rem;
  }

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 30px;

    & .card-top {
      display: flex;
      flex-direction: column;
      width: 150px;
      gap: 0.25rem;
      margin-bottom: 0.25rem;
      color: rgba(128, 128, 128, 1);

      &-icon {
        display: flex;
        align-items: center;
        gap: 4px;

        & img {
          width: auto;
          height: auto;
        }
      }

      & p {
        font-weight: 500;
        text-align: left;
      }
    }
    &-origin,
    &-destination {
      img {
        opacity: 0.6;
      }
    }
    &:first-of-type {
      align-items: flex-start;
    }
    &:last-of-type {
      align-items: flex-end;

      & .card-top {
        align-items: flex-end;
      }
    }

    @media screen and (max-width: 1024px) {
      &-origin,
      &-destination {
        display: none;
      }
      // &:nth-child(3) {
      //   align-items: flex-start;
      // }
      // &:nth-child(5) {
      //   align-items: flex-end;
      // }
    }
    img {
      object-fit: contain;
      width: 2.5rem;
      height: 2.5rem;
    }
    &-strong {
      // margin-top: 0.3rem;
      font-weight: 600;
      min-width: max-content;
      color: #1a202c;
      text-wrap: nowrap;
      margin-block: 0.25rem;
    }
    &-country {
      // margin-bottom: 0.3rem;
      color: #808080;
      text-wrap: nowrap;
    }
    &-readiness {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: auto;
      > p {
        font-weight: 500;
        color: #4d5354;

        > span {
          font-weight: 400;
        }
      }
    }
  }
  &-separator-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: relative;

    &-origin,
    &-destination {
      & img {
        opacity: 0.6;
      }
    }

    @media screen and (max-width: 1024px) {
      &-origin,
      &-destination {
        display: none;
      }
    }

    & .transit {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -95%);
      display: flex;
      align-items: center;
      gap: 8px;
      color: #157ffb;
      font-weight: 500;

      & img {
        object-fit: contain;
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
  &-separator {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    padding-bottom: 1.5rem;
    background-image: linear-gradient(
      to right,
      #4d5354 50%,
      rgba(77, 83, 84, 0) 0%
    );
    background-position: 0% 50%;
    background-size: 32px 4px;
    background-repeat: repeat-x;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  &-blue-separator {
    @media screen and (max-width: 1024px) {
      display: flex;
    }
    background-image: linear-gradient(
      to right,
      #157ffb 50%,
      rgba(21, 127, 251, 0) 0%
    );
    > p {
      color: #157ffb;
    }
  }
}

.frieghtButtonClassName {
  padding-block: 1.5rem;
}
