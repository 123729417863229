.header {
  background-color: #fff;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 100;
  position: fixed;
  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0.75rem 2rem;
  }

  .logo {
    display: flex;
    min-width: 180px;
    align-items: center;
    gap: 8px;
    img {
      max-width: 3.5rem;
      max-height: 3.5rem;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    p {
      font-weight: bold;
      color: #bd3c31;
      font-size: var(--md-font-size);
    }
  }
  ul {
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    padding-left: 0;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      word-break: break-all;

      h4 {
        font-size: 16px;
      }
    }
    a {
      text-decoration: none;
      color: #1c1c1c;
    }

    .profile-dropdown {
      position: relative;
      gap: 0.5rem;
      background-color: #eee;
      padding: 0.25rem 0.5rem 0.25rem 0.25rem;
      border-radius: 40px;

      .dropDownOptions {
        background-color: #fff;
        box-shadow: 0px 10px 20px 0px #50505033;
        position: absolute;
        top: 6rem;
        right: 0;
        padding: 20px 15px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 500;
        min-width: max-content;
        min-height: 132px;
        z-index: 1;

        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          padding: 10px 15px;
          border-radius: 5px;
          transition: all 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
            background-color: #f8f8f8;
          }
        }

        a {
          color: #546169;
        }
      }
      .salesDropDownOptions {
        background-color: #fff;
        box-shadow: 0px 10px 20px 0px #50505033;
        position: absolute;
        top: 100%;
        right: 10%;
        padding: 20px 15px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 500;
        min-width: max-content;
        height: Auto;
        // min-height: 132px;
        z-index: 1;

        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          // padding: 10px 15px;
          border-radius: 5px;
          transition: all 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
            background-color: #f8f8f8;
          }
        }

        a {
          color: #546169;
        }
      }
    }
  }
  .credit {
    background-color: white;
    display: flex;
    align-items: center;
    border: none;
    p {
      font-size: larger;
      margin-inline-start: 1rem;
    }
  }
  .notification {
    background-color: white;
    border: none;
    cursor: pointer;
  }
  .menu-icons {
    width: 24px;
    height: 24px;

    img {
      width: 24px;
      height: 24px;
    }
  }
  .profilePic {
    border: none;
    cursor: pointer;
    padding-inline: 0rem;
    display: flex;
    background: none;

    .img {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      object-fit: cover;
    }

    .open {
      transform: rotate(180deg);
    }

    .arrow {
      width: 1rem;
      height: 1rem;
      opacity: 0.5;
    }
  }
  .web-tabs {
    border: none;
    width: 441px;
    align-items: center;
    justify-content: center;
  }
  .mobile-tabs {
    border: none;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (max-width: 768px) {
  .header {
    ul {
      justify-content: space-between;
    }
    .mobile-tabs {
      width: 100%;
    }
  }
}
.notificationButton {
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 50px; /* Adjust based on notification icon position */
  right: 0;
  width: 300px;
  background-color: white;
  border-radius: 0.6rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  padding: 1rem;
}
.dropdownHeader {
  display: flex;
  justify-content: space-between;
  &-link {
    color: #157ffb !important;
  }
  &-link-disabled {
    color: lightgrey !important;
  }
}
.dropdownTitle {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.notificationsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notificationItem {
  border-bottom: 1px solid #e2e3e3;
  padding: 0.5rem 0;
  &-date {
    display: block;
  }
}

.notificationItem h5 {
  margin: 0;
  font-size: 1rem;
}

.notificationItem p {
  margin: 0.2rem 0 0;
  font-size: 0.9rem;
  color: #555;
}

/* Styles for seen notifications */
.seenNotification {
  background-color: #f7f7f7;
  color: #888;
  cursor: pointer;
}

/* Styles for unseen notifications */
.unseenNotification {
  background-color: #eaf4ff;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}
.notificationButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
}

.dropdown {
  position: absolute;
  top: 4.7rem; /* Adjust based on notification icon position */
  right: 0px;
  width: 85vw;
  height: 100vh;
  @media screen and (min-width: 768px) {
    width: 550px;
    height: 680px;
    overflow-y: auto;
  }
  @media screen and (max-width: 768px) {
    position: fixed;
    top: 56.4px;
    height: 100vh;
    overflow-y: auto;
    border-radius: 0;
    padding-bottom: 90px;
  }
  background-color: white;
  border-radius: 0.6rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  padding: 1rem;
  h3 {
    font-weight: 500;
  }
}

.dropdownTitle {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-inline-start: 0.4rem;
}

.notificationsList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: flex-start;
  // align-items: flex-start;
  // justify-content: flex-start;
}

.notificationItem {
  border-bottom: 1px solid #e2e3e3;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.notificationItem :hover {
  background-color: #eaf4ff;
}

.notificationItem h5 {
  margin: 0;
  font-size: 1rem;
}

.notificationItem p {
  margin: 0.2rem 0 0;
  font-size: 0.9rem;
  color: #555;
  width: 100%;
}

/* Styles for seen notifications */
.seenNotification {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  background-color: white;
  color: #888;
}

/* Styles for unseen notifications */
.unseenNotification {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  background-color: #eaf4ff;
  color: #333;
  font-weight: bold;
}
.seenNotification:hover,
.unseenNotification :hover {
  background-color: #eaf4ff;
}
