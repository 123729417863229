.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/AuthBg.png);
  background-size: cover; /* Scale the image to cover the entire area */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center;
  overflow-x: hidden;
  overflow-y: visible;
  &-container {
    background-color: #fff;
    width: 42rem;
    height: auto;
    padding-block: 4rem;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    // gap: 1rem;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .login {
    background-image: none;
  }
}
