.signup {
  &-title {
    font-weight: 500;
    font-size: var(--md-font-size);
    color: rgba(189, 60, 49, 1);
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &-header {
    width: 100%;
    height: 17%;
    margin-bottom: 1rem;
    @media screen and (width <= 2140px) {
      margin-bottom: 1rem;
    }
  }
  &-subtitle {
    // font-family: Poppins;
    font-size: var(--xl-font-size);
    font-weight: 500;
  }
  &-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;

    @media screen and (width <= 2140px) {
      gap: 10px;
      margin-bottom: 0.5rem;
    }
  }
  &-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    div {
      display: flex;
      flex-direction: column;
      // gap: 0.5rem;
      p {
        // color: rgba(189, 60, 49, 1);
        text-transform: capitalize;
      }
    }
    a {
      font-size: 0.875rem;
      color: rgba(189, 60, 49, 1);
      // margin-bottom: 2rem;
    }
    button {
      padding-block: 0.75rem;
    }
  }
}
.forget-password {
  display: flex;
  align-self: flex-end;
  color: #4d5354 !important;
  font-weight: 500;
}
.error {
  color: rgba(189, 60, 49, 1);
}
.sign-up {
  text-align: center;
  color: rgba(152, 162, 179, 1) !important;
  font-weight: 400;
  font-size: var(--md-font-size);
  > span {
    color: rgba(189, 60, 49, 1);
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .signup {
    &-container {
      height: 100%;
      justify-content: space-between;
    }
    &-form {
      height: 100%;
      justify-content: space-between;
    }
  }
  .forget-password {
    display: flex;
    align-self: flex-end;
  }
}
