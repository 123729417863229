// Custom Margin For The Sales SideBar
.sales-side-bar {
  // @media (min-width: 1400px) and (max-width: 1800px) {
  padding-top: 40px;
  // }
  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
}

.side-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  width: auto; /* Adjust width as needed */
  backface-visibility: hidden;
  z-index: 10;
  height: 100%; /* Full height of the viewport */
  position: fixed; /* Position it fixed to the left */
  top: var(--header-height);
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  .container {
    display: flex;
    // margin-top: 10px;
    flex: 1;
    width: 100%;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
      margin-top: calc(var(--header-height) + 20px);
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 5px 20px 20px 24px;

      @media screen and (max-width: 430px) {
        padding-bottom: 0px;
      }

      &-logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;

        p {
          font-weight: bold;
          color: #bd3c31;
          font-size: var(--sm-font-size);
        }
      }
    }
    &-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px 18px 10px 14px;
      margin-top: 10px;
    }
    &-hr {
      margin-top: 25px;
      min-width: 10px;
      border: none;
      border: 1px solid rgba(237, 237, 237, 1);

      @media screen and (max-width: 430px) {
        margin-top: 10px;
      }
    }
  }
  .logo {
    margin-right: 10px;
    // padding-inline-start: 1.2rem;
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background: none;
    p {
      padding-inline-start: 10px;
      text-align: start;
      // min-width: max-content;
    }
  }
  .imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    &-active {
      background-color: rgba(189, 61, 44, 1);
    }
    &-count {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      background-color: rgba(189, 61, 44, 1);
      border-radius: 50%;
      p {
        color: white;
      }
    }
  }
  .img {
    width: 40px;
    height: 40px;
    max-width: 55px;
    max-height: 55px;
    cursor: pointer;

    @media screen and (max-width: 1400px) {
      width: 32px;
      height: 32px;
    }
  }
  .li {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: start;
    cursor: pointer;
    padding: 1rem;

    @media screen and (max-width: 430px) {
      padding: 0.5rem;
    }
  }
  .selectedLi {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: start;
    background-color: rgba(255, 240, 240, 1);
    // height: 62px;
    padding: 1rem;
    // padding-left: 0;
    border-radius: 12px;
    cursor: pointer;

    @media screen and (max-width: 430px) {
      padding: 0.5rem;
    }
  }
  .text {
    font-size: var(--md-font-size);
    text-wrap: wrap;
    font-weight: 400;
    opacity: 0.8;
    cursor: pointer;
    text-transform: capitalize;
  }
  .group {
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto;
    width: 100%;
  }
  .logout-group {
    position: absolute;
    bottom: 30px;

    @media screen and (max-width: 430px) {
      bottom: 15px;
    }
  }
  .logout-sales {
    bottom: 125px;
  }
  .title {
    padding-inline-start: 1rem;
    padding-block: 1rem;

    @media screen and (max-width: 430px) {
      padding-block: 0.5rem;
    }
  }
  .ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-inline: 1rem;
    gap: 0.5rem;
  }
}
.credit {
  background: none;
  border: none;
  align-items: center;
}
@media (max-width: 1420px) {
  .side-bar {
    // width: 18.677vw;
    .li {
      margin-inline-start: 0rem;
    }
    .selectedLi {
      margin-inline-start: 0rem;
      width: 100%;
    }
    .logo {
      padding-inline-start: 0rem;
    }
    .title {
      padding-inline-start: 10px;
      padding-bottom: 1rem;
    }
    .text {
      padding-inline-end: 1rem;
      color: black;
    }
  }
}
@media (max-width: 992px) {
  .side-bar {
    // width: 22.677vw;
    .li {
      margin-inline-start: 0rem;
    }
    .selectedLi {
      margin-inline-start: 0rem;
      width: 100%;
    }
    .logo {
      padding-inline-start: 0rem;
    }
    .title {
      padding-inline-start: 24px;
      padding-bottom: 1rem;
    }
    .text {
      padding-inline-end: 1rem;
    }
  }
}
@media (max-width: 768px) {
  .side-bar {
    z-index: 5;
    width: 90%;
    max-width: 314px;
    position: fixed; /* Position it fixed to the left */
    top: 0;
    left: 0;
    height: 100%;
    box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.2);
    .logo {
      justify-content: flex-start;
    }
    .ul {
      padding-inline-start: 10px;
    }
  }
  .profilePic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border: none;
    cursor: pointer;
  }
  .credit {
    background: none;
    border: none;
    align-items: center;
  }
  .logo {
    justify-content: flex-start;
  }
}
.count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-right: 1rem;
  width: 25px;
  height: 25px;
  background-color: rgba(189, 61, 44, 1);
  position: absolute;
  top: 2%;
  right: 10%;
  border-radius: 50%;
  p {
    color: white;
  }
}
