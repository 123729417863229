.search-bar {
  // margin-top: 1rem;
  display: flex;
  background: white;
  border: 1px solid #ededed;
  border-radius: 40px;
  height: 48px;
  width: 100%;
  max-width: 812px;
  padding-inline: 1rem;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1rem;
  gap: 1rem;
  &-input {
    max-width: 866px;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    font-size: var(--md-font-size);
    outline: none;
    color: #1b2029;

    &::placeholder {
      opacity: 0.7;
    }
  }
}
