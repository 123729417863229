.container {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  flex: 1;

  > label {
    background: transparent;
    font-size: var(--md-font-size);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  > textarea {
    border: 0.8px solid #d9dcdc;
    border-radius: 6px;
    padding-inline: 1rem;
    padding-block: 0.75rem;
    resize: none;
    font-size: 0.875rem;

    &:focus {
      outline: none;
      border: 1px solid rgba(0, 122, 255, 1);
    }

    &::placeholder {
      font-size: 0.875rem;
      color: #bababa;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #bababa;
    }

    &::-ms-input-placeholder {
      color: #bababa;
    }
  }
}
