.card {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  border: 1px solid rgba(204, 204, 204, 0.5);
  padding: 24px;
  overflow: auto;

  hr {
    border: none;
    outline: none;
    height: 1px;
    background-color: #dedcdc;
    margin-block: 1.5rem;
    width: 100%;
    min-width: 800px;

    &:first-of-type {
      margin-block: 1.5rem 3rem;
    }
  }

  &-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 800px;

    &-header {
      display: flex;
      flex-direction: column;
      gap: 10px;

      p {
        color: #4d5354;
        line-height: 20px;
        font-weight: 400;
        font-size: 16px;
        text-transform: capitalize;

        span {
          font-weight: bold;
        }
      }
    }

    .status {
      width: max-content;
      padding-inline: 10px;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1.5rem;
      text-transform: capitalize;
      font-size: 16px;
    }
    .active {
      background-color: #e6f7ff;
      color: #1890ff;
    }
    .new {
      background-color: #f3e5f5;
      color: #9c27b0;
    }
    .expired {
      background-color: #e0e0e0;
      color: #9e9e9e;
    }
    .accepted {
      background-color: #e8f5e9;
      color: #4caf50;
    }
    .pending {
      background-color: #fff8e1;
      color: #ffb300;
    }
  }
  &-shipping-row {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    min-width: 800px;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 30px;
      position: relative;

      .port-date {
        min-width: max-content;
        position: absolute;
        top: -37px;
        font-size: 14px;
        & span {
          font-weight: 500;
        }
      }
      // flex: 1;
      &-origin,
      &-destination {
        img {
          opacity: 0.6;
        }
      }
      &:first-of-type {
        align-items: flex-start;
      }
      &:last-of-type {
        align-items: flex-end;
      }

      img {
        object-fit: contain;
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 0.3rem;
      }
      &-strong {
        font-weight: 600;
        min-width: max-content;
        color: #1a202c;
        text-wrap: nowrap;
        font-size: 16px;
      }
      &-country {
        color: #808080;
        text-wrap: nowrap;
        font-size: 16px;
      }

      p {
        &:last-of-type {
          margin-top: 8px;
          color: #4d5354;
          font-weight: 500;
          line-height: 16px;
          width: max-content;
          font-size: 16px;

          span {
            font-weight: 400;
          }
        }
      }
    }
    &-separator-container {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      position: relative;
      justify-content: center;

      &-origin,
      &-destination {
        & img {
          opacity: 0.6;
        }
      }

      & img {
        object-fit: contain;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-self: center;
        position: absolute;
        margin-bottom: 1rem;
      }
      & .transit {
        align-items: center;
        color: #157ffb;
        display: flex;
        font-weight: 500;
        gap: 8px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        img {
          position: relative;
          margin-bottom: 0;
        }
        span {
          width: max-content;
          font-size: 14px;
        }
      }
    }
    &-separator {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
      padding-bottom: 1.5rem;
      background-image: linear-gradient(
        to right,
        #4d5354 60%,
        rgba(77, 83, 84, 0) 0%
      );
      background-position: 0% 98%;
      background-size: 15px 2px;
      background-repeat: repeat-x;
      opacity: 0.6;
    }
    &-blue-separator {
      @media screen and (max-width: 1024px) {
        display: flex;
      }
      background-image: linear-gradient(
        to right,
        #157ffb 60%,
        rgba(21, 127, 251, 0) 0%
      );
      > p {
        color: #157ffb;
      }
    }
  }
  .timeline-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 800px;

    &-item {
      display: flex;
      align-items: center;
      gap: 10px;

      h4 {
        color: #4d5354;
        line-height: 20px;
        font-weight: 500;
        font-size: 18px;
      }
      h3 {
        color: #808080;
        font-weight: 400;
        line-height: 20px;
        font-size: 16px;
      }
      p {
        color: #262a2a;
        font-weight: 400;
        line-height: 27px;
        font-size: 18px;
        max-width: 500px;
      }
    }
  }
}
