.date-input-container {
  width: 100%;
  max-width: none;
  row-gap: 0.3rem;
  position: relative;

  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .date-input-container {
    max-width: unset;
  }
}
.date-input.date-input {
  width: 100%;
  background: rgba(255, 255, 255, 0.17);
  border-radius: 0.5rem;
  border: 1px solid rgba(202, 202, 202, 0.5);
  overflow: hidden;
  min-height: 3.3rem;
  justify-content: center;
  & > div {
    width: 100%;
    border-radius: 0.5rem;
    border: none;
    & * {
      border: none;
    }
  }
  // input::placeholder {
  //   color: #B7B7B7;
  // }
  svg {
    color: white;
  }
  input:focus {
    color: black; /* Change this to your desired color */
  }
  input:end {
    color: black;
  }

  &-origin {
    display: flex;
    place-items: center;
    border: 2px;
    gap: 8px;
    color: white;
    &:active {
      border-color: white;
    }
    // border-radius: 0.5rem;
  }
}
@media screen and (max-width: 1920px) {
  .date-input.date-input {
    min-height: 3.6rem;
  }
}
@media screen and (max-width: 1600px) {
  .date-input.date-input {
    min-height: 3.9rem;
  }
}
@media screen and (max-width: 1400px) {
  .date-input.date-input {
    min-height: 4.3rem;
  }
}
// .labelView {
//   margin-top: 10px;
//   margin-bottom: 10px;
//   margin-left: 5px;
//   &-label {
//     color: white;
//   }
// }
.labelView-light {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  &-label {
    color: white;
  }
}
/* dark variation*/

.date-input-dark {
  width: 100%;
  background: rgba(255, 255, 255, 0.17);
  border-radius: 0.5rem;
  border: 1px solid rgba(202, 202, 202, 0.5);
  overflow: hidden;
  & > div {
    width: 100%;
    border-radius: 0.5rem;
    border: none;
    & * {
      border: none;
    }
  }
  &-origin {
    display: flex;
    place-items: center;
    border: 2px;
    gap: 8px;
    color: white;
    &:active {
      border-color: white;
    }
    // border-radius: 0.5rem;
  }
}

.date-error {
  color: #fb5b5b;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  position: absolute;
  top: 100%;
}

.date-input-error {
  border: 1px solid #fb5b5b !important;
}
