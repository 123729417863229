.remaining-shipments {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 24px;

  p {
    color: #4d5354;
    font-weight: 500;
    font-size: 16px;
  }
}
