.initial-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  color: #aaa6a6;
  text-align: center;

  h3 {
    font-weight: 500;
  }

  img {
    width: 250px;
  }
}
