.form-stepper {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  padding: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    padding-top: 1rem;
    padding-inline: 24px;
  }

  &-step {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    min-width: fit-content;
    @media screen and (max-width: 1600px) {
      column-gap: 0.2rem;
    }

    &-indicator {
      min-width: 1.5rem;
      min-height: 1.5rem;
      border-radius: 999px;
      border: 4px solid #98a2b3;
      background-color: transparent;

      // @media screen and (max-width: 1600px) {
      //   min-width: 18px;
      //   min-height: 18px;
      // }

      // @media screen and (max-width: 1200px) {
      //   min-width: 14px;
      //   min-height: 14px;
      // }

      &-active {
        border: 5px solid #e7efff;
        background-color: #157ffb;
      }

      &-completed {
        border: none;
        background-color: #0ebc93;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-name {
      font-size: 1.1rem;
      color: #808b8c;
      min-width: fit-content;
      display: inline-flex;

      @media screen and (max-width: 1200px) {
        font-size: var(--sm-font-size);
      }

      @media screen and (max-width: 1024px) {
        font-size: var(--sm-font-size);
        display: none;
      }

      &-active {
        color: #0d0e0e;
      }
    }
  }
}
