.img-btn {
  cursor: pointer;
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-self: flex-start;
  background: none;
  border: none;
  padding-left: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
