.form-container {
  padding-inline: 2.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  // height: 100%;
  // align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    padding-inline: 24px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 10%;
  }

  &-row {
    display: flex;
    align-items: flex-end;
    column-gap: 1rem;
    flex-direction: row;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      row-gap: 1rem;
    }

    &-title {
      align-items: flex-start;
      margin-bottom: 0.5rem;

      @media screen and (width > 1024px) {
        display: none;
      }

      h2 {
        color: #0d0e0e;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
      }
    }

    &-disabled {
      opacity: 0.4;
    }

    &-options-container {
      flex: 1;
      row-gap: 0.5rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      @media screen and (max-width: 1024px) {
        // margin-bottom: 1rem;
      }
      > label {
        font-size: var(--md-font-size);
      }
    }
  }

  .containers-parent {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .form-container-row {
      @media screen and (max-width: 1024px) {
        margin-bottom: 16px;
      }

      .label {
        font-size: var(--md-font-size);
      }
    }
  }

  &-dangerousCargo {
    align-items: flex-start;
  }

  &-info {
    display: flex;
    align-items: flex-start;
    column-gap: 0.5rem;

    > img {
      margin-top: 0.3rem;
      width: 2rem;
    }

    > p {
      font-size: var(--sm-font-size);
      color: #aaa6a6;
    }
  }
}

.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2.5rem;
  padding: 1rem;

  > h6 {
    font-weight: 500;
    font-size: 1.25rem;
    color: #1c1c1c;
    text-align: center;
  }
  > p {
    font-size: 1.1rem;
    color: #667085;
    text-align: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;

    > button {
      font-size: 1.1rem !important;
      // padding-block: 0rem !important;
      &:nth-child(2) {
        color: #bd3c31 !important;
        background-color: transparent !important;
        padding-inline: 0 !important;
      }
    }
  }
}

.dateInputHeight {
  min-height: 3.1rem !important;
  height: 3.1rem !important;
}

// Styles For The DropDownWithCheckboxSelect
.container {
  row-gap: calc(0.3rem - 2px);
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
}

.unit-style {
  inset-block-start: 27% !important;
}

.remove-btn-container {
  position: relative;
  flex: 1 1;
  row-gap: 0.3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
}
