.success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }
  img {
    margin-block: 1rem;
  }
  &-h3 {
    margin-top: 1rem;
    // padding-inline-start: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
  }
  &-h6 {
    margin-top: 1rem;
    padding-inline-start: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: center;
    // margin-bottom: 1rem;
  }
  &-button {
    margin-top: 2rem;
    display: flex;
    width: 99%;
    justify-content: flex-end;
    margin-bottom: 1rem;
    &-btn {
      width: 14.18rem !important;
      &-disabled {
        background-color: transparent !important;
        border: grey 1px solid !important;
        gap: 0.35rem;
        p {
          color: gray;
        }
      }
    }
  }
  &-button-success {
    margin-top: 2rem;
    display: flex;
    width: 99%;
    justify-content: center;
    row-gap: 2rem;
    &-btn {
      width: 14.18rem !important;
    }
  }
}
