.request-quote {
  background-image: url("../../assets/request-quote-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;

  &-form {
    background-color: white;
    padding-block: 1.3rem 40px;
    border-radius: 10px;
    width: 60%;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      overflow: hidden;
    }

    &-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.3rem;
      padding-inline: 2.5rem;

      @media screen and (width < 1024px) {
        padding-inline: 24px;
      }

      .burger-icon {
        @media screen and (width > 1024px) {
          display: none;
        }
      }

      > h3 {
        font-weight: 500;
        color: #0d0e0e;
        font-size: var(--lg-font-size);
      }

      > button {
        all: unset;
        cursor: pointer;
      }

      .close-img {
        @media screen and (width < 1024px) {
          width: 24px;
          height: 24px;
        }
      }
    }

    > p {
      display: none;
      @media screen and (max-width: 1024px) {
        display: inline-flex;
        font-size: 1.2rem;
        color: #2e333e;
        padding-inline-start: 24px;
      }
    }

    > hr {
      border-top: 0.2px solid #e1e1e6;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
}
