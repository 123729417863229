.home {
  padding: 1.5rem 3rem 3rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &-header {
    display: flex;
    align-items: center;
    gap: 1rem;

    h2 {
      font-size: 28px;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .home-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0;
    // padding-bottom: 1rem;
  }
  &-main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 1300px) {
      flex-wrap: wrap;
    }

    h2 {
      font-size: 22px;
      margin-bottom: 20px;
      color: #1b2029;
      padding-bottom: 1rem;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    &-content {
      width: 100%;
      // padding-inline-start: 0.5rem;
    }
  }
  &-card {
    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: center;
    max-width: 594px;
    // justify-content: space-between;
    // height: 45vh;

    img {
      width: 250px;
      height: 250px;
    }
  }
  .title {
    font-size: larger;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #1c1c1c;
    text-align: center;
    letter-spacing: 1px;
    // opacity: 0.8;
  }
  .paragraph {
    // opacity: 0.5;
    color: #667085;
    line-height: 27px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.8px;
    text-align: center;
    margin-bottom: 28px;
  }
  .btn {
    width: 227px;
    height: 52px;
    background-color: rgba(189, 60, 49, 1);
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  .btnText {
    color: white;
    font-size: 20px;
    font-weight: 500;
  }
  .logo {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    border: none;
    background: none;
    gap: 3px;
  }
  .text {
    font-size: var(--lg-font-size);
    font-weight: 500;
    opacity: 0.8;
    cursor: pointer;
  }
  .img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  .separator {
    height: 20px;
    width: 1.5px;
  }
}
@media (max-width: 768px) {
  .home {
    // align-items: center;
    // padding: 0;
    margin-left: 0;
    padding-inline: 12px 6px;
    // gap: 90px;
    &-main {
      flex-direction: column;
      width: 100%;
      justify-content: center;
    }
  }
}

.skeleton-styles {
  min-width: unset !important;
}
