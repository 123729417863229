.container {
  flex: 1;
  // row-gap: 0.3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  @media screen and (max-width: 1024px) {
    // margin-bottom: 1rem;
  }
  > label {
    font-size: var(--md-font-size);
  }
}

.menuItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &-label {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &-count {
    display: flex;
    column-gap: 1rem;

    > span {
      font-size: var(--md-font-size);
      color: #06329d;
    }

    > button {
      all: unset;
      cursor: pointer;
    }
  }
}

.icon {
  margin-inline-end: 1rem;
}

.optionLabel {
  font-size: 20rem !important;
}
