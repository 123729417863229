.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  flex: 1;
  position: relative;
  &-disabled {
    opacity: 0.4;
  }
  .form-error {
    position: absolute;
    top: 100%;

    color: #fb5b5b;
    font-size: 0.75rem;
    padding-inline-start: 1rem;
    position: absolute;
    top: 100%;
  }
  .input-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    opacity: 1;
    border-radius: 0.5rem;
    position: relative;
    flex-direction: column;
    row-gap: 0.3rem;
    &:focus,
    &:focus-visible,
    &:focus-within {
      input::placeholder {
        text-align: start;
        color: #bfbfbf;
      }

      input:-ms-input-placeholder {
        text-align: start;
        color: #bfbfbf;
      }

      input::-webkit-input-placeholder {
        text-align: start;
        color: #bfbfbf;
      }
      input {
        text-align: start;
      }
    }
    .eye {
      cursor: pointer;
      position: absolute;
      inset-inline-end: 1rem;
      inset-block-start: 50%;
      transform: translateY(-50%);
    }
    .unit {
      font-size: var(--sm-font-size);
      color: #aaa6a6;
      position: absolute;
      inset-inline-end: 1rem;
      inset-block-start: 57%;
    }
    input {
      background: transparent;
      height: 100%;
      width: 100%;
      padding-inline: 1rem;
      padding-block: 0.75rem;
      font-size: 0.875rem;
      box-sizing: border-box;
      color: inherit;
      border: 0.8px solid #d9dcdc;
      border-radius: 6px;
    }
    .input-error {
      border: 1px solid #fb5b5b;
      &:focus,
      &:focus-visible,
      &:focus-within {
        border: 1px solid #fb5b5b;
      }
    }

    input:focus {
      outline: none;
      border: 1px solid rgba(0, 122, 255, 1);
    }

    input::placeholder {
      font-size: 0.875rem;
      color: #bababa;
      opacity: 1;
    }

    input:-ms-input-placeholder {
      color: #bababa;
    }

    input::-ms-input-placeholder {
      color: #bababa;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[name="cvc"] {
      padding-inline-end: 2rem;
    }
    &-reverse {
      & input {
        text-align: start;
        padding-inline-start: 1rem;
      }
    }
    label {
      background: transparent;
      font-size: var(--md-font-size);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
  }
  // .input-filled {
  //   position: absolute;
  //   scale: 0.8;
  //   background-color: #fff;
  //   color: #333;
  //   transform: translateY(-30px);
  //   transition: all 250ms ease-in-out;
  // }
  // img {
  //   object-fit: contain;
  //   padding-inline-end: 0.75rem;
  // }
  // p {
  //   font-size: 12px;
  //   margin: 0.5rem 0.5rem 0;
  //   color: red;
  // }
}
