.modalContent {
  padding: 1.5rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  height: 100%;
  position: relative;

  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & span {
      font-weight: bold;
    }
  }

  .croppedPageContainer {
    width: max-content;
    height: 85%;
    overflow: hidden;
    position: relative;
    border: 1px solid #676767;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .pageContainer {
    transform: translate(0px, 0rem); /* Adjust the viewable part of the PDF */
    position: absolute;
    width: 100%;
    height: 100%;
    zoom: 0.8;
  }

  .pdfContainer {
    width: auto;
    height: fit-content;
    margin-inline: auto;
  }

  .downloadBtn {
    all: unset;
    cursor: pointer;
    background-color: transparent;
    border: 2.5px solid #06329d;
    padding: 0.5rem 0.75rem;
    border-radius: 2rem;
    font-weight: 500;
    color: #06329d;
    align-self: flex-end;
    transition: all ease-in-out 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
  .buttonsContainer {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: flex-end;
    width: 100%;
    // padding-bottom: 1rem;

    > button {
      width: 20%;
    }
    & .accepted {
      > button {
        display: flex;
        column-gap: 5px;
        &:first-of-type {
          background-color: transparent !important;
          color: black;
          border: 1px solid #d1d1d1;
        }
      }

      & .completed {
        border: none;
        background-color: #0ebc93;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 1.5rem;
        min-height: 1.5rem;
        border-radius: 50%;
      }
    }
  }
}

.baseModalHeight {
  height: auto !important;
}
