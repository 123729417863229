.container {
  flex: 1;
  row-gap: 0.3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;

  &-disabled {
    opacity: 0.4;
  }
  @media screen and (max-width: 1024px) {
    // margin-bottom: 1rem;
  }
  > label {
    font-size: var(--md-font-size);
  }
}

.icon {
  margin-inline-end: 1rem;
}
