.clientDetailsCard {
  // padding: 1rem;
  height: auto;
  border-radius: 1rem;
  margin-top: 1rem;

  h3 {
    font-size: var(--lg-font-size);
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .card {
    padding-block: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 1.25rem;
    height: 100%;
    padding-inline: 2rem;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-block: 1rem;
    }
    // @media screen and (max-width: 685px) {
    //   flex-direction: column;
    // }
    // padding: 1.5rem;
    // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }

  .clientInfo {
    display: flex;
    align-items: center;
    height: 80%;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .avatar {
      width: 4.9375rem;
      height: 4.9375rem;
      border-radius: 50%;
      margin-right: 2rem;
    }

    .nameAndPosition {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      h4 {
        margin: 0;
        font-size: 1.1rem;
        font-weight: 600;
      }

      p {
        margin: 0;
        font-size: 0.9rem;
        color: #666;
      }
    }
  }

  .contactInfo {
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: space-around;
    .contactItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;

      img {
        width: 16px;
        margin-right: 0.5rem;
      }

      p {
        font-size: 0.9rem;
        color: #333;
      }
    }
  }
}
