.new-password-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  > div {
    display: flex;
    width: 100%;
    // margin-top: 20px;
    row-gap: 1rem;
    flex-direction: column;
  }
  p {
    margin-bottom: 0.2rem;
  }

  > button {
    margin-top: 1rem;
  }

  &-subtitle {
    display: flex;
    font-weight: 500;
    font-size: 1.75rem;
    justify-self: flex-start;
    align-self: flex-start;
    padding-bottom: 0.5rem;
    font-size: var(--xl-font-size);
  }
}
@media (max-width: 768px) {
  .new-password-container {
    height: 100%;
    width: 100%;
    justify-content: space-between;

    // &-btn {
    //   width: 95.1%;
    //   height: 3rem;
    // }
  }
}
