.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  position: relative;
  .form-error {
    position: absolute;
    top: 100%;
    left: 0;
    color: #fb5b5b;
    font-size: 0.75rem;
    padding-inline-start: 1rem;
  }

  .input-container {
    background: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.875rem;
    opacity: 1;
    border: 1px solid rgba(238, 238, 238, 1);
    // padding-inline-start: 0.2rem;
    border-radius: 0.5rem;
    position: relative;
    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 1px solid rgba(0, 122, 255, 1);
      input::placeholder {
        text-align: start;
        color: #bfbfbf;
      }

      input:-ms-input-placeholder {
        text-align: start;
        color: #bfbfbf;
      }
      // input:-webkit-autofill:focus {
      //   -webkit-box-shadow: 0 0 0 30px white inset !important; /* Change 'white' to match your form background color */
      //   box-shadow: 0 0 0 30px white inset !important; /* Change 'white' to match your form background color */
      //   -webkit-text-fill-color: black !important;
      // }
      input::-webkit-input-placeholder {
        text-align: start;
        color: #bfbfbf;
      }
      input {
        text-align: start;
      }
    }
    &-error {
      border: 1px solid #fb5b5b;
      &:focus,
      &:focus-visible,
      &:focus-within {
        border: 1px solid #fb5b5b;
      }
    }
    .eye {
      cursor: pointer;
      position: absolute;
      inset-inline-end: 1rem;
      inset-block-start: 50%;
      transform: translateY(-50%);
    }
    input {
      background: transparent;
      height: 100%;
      width: 100%;
      border: none;
      padding-inline: 1rem;
      font-size: 0.875rem;
      box-sizing: border-box;
      color: inherit;
    }

    input:focus {
      outline: none;
    }

    input::placeholder {
      font-size: 0.875rem;
      color: #bababa;
      opacity: 1;
    }

    input:-ms-input-placeholder {
      color: #bababa;
    }

    input::-ms-input-placeholder {
      color: #bababa;
    }

    input[name="cvc"] {
      padding-inline-end: 2rem;
    }
    &-reverse {
      & input {
        text-align: start;
        padding-inline-start: 1rem;
      }
    }
    label {
      background: transparent;
      font-size: 0.875rem;
      padding-inline-start: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 0.5rem 0;
    }
  }
  .label-icon {
    padding-inline: 1rem;
  }
  // .input-filled {
  //   position: absolute;
  //   scale: 0.8;
  //   background-color: #fff;
  //   color: #333;
  //   transform: translateY(-30px);
  //   transition: all 250ms ease-in-out;
  // }
  // img {
  //   object-fit: contain;
  //   padding-inline-end: 0.75rem;
  // }
  // p {
  //   font-size: 12px;
  //   margin: 0.5rem 0.5rem 0;
  //   color: red;
  // }
}
