.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  &-btn-container {
    margin-top: 3rem;
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    p {
      font-size: var(--md-font-size);
      // color: #808080;
      text-transform: capitalize;
      // padding-bottom: 0.25rem;
    }
  }
  button {
    padding-block: 0.625rem !important;
  }
}
@media (max-width: 768px) {
  .form {
    height: 100%;
    justify-content: space-between;
  }
}
