.shipment-info {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    height: 100vh;
    align-items: flex-start;
    justify-content: flex-end;
  }
  // justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin-top: 90px;
  gap: 1.8rem;
  overflow-y: hidden;

  &-container {
    margin-inline-start: 4rem;
    width: 50%;
    @media screen and (max-width: 768px) {
      display: none;
    }
    height: 100%;
    min-height: 100vh;
    // overflow-y: auto;
    scrollbar-width: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  &-container::-webkit-scrollbar {
    display: none;
  }
  &-description {
    padding-top: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  &-title {
    display: flex;
    gap: 1.5rem;
    width: 100%;
    justify-content: space-between;
    p {
      font-size: var(--lg-font-size);
      font-weight: 500;
      min-width: max-content;
    }
    &-text {
      display: flex;
      gap: 1rem;
    }
  }
  &-success-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-block: 1rem;
    }
  }
  &-map {
    width: calc(50% - 7rem);
    height: 90%;
    position: fixed;
    right: 0;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
      top: 55px;
    }
  }
  &-modal {
    display: flex;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  &-h3 {
    margin-top: 3rem;
    padding-inline-start: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  &-h6 {
    margin-top: 1rem;
    padding-inline-start: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: center;

    // margin-bottom: 1rem;
  }
  &-button {
    margin-top: 2rem;
    display: flex;
    width: 99%;
    justify-content: flex-end;
    &-btn {
      width: 14.18rem !important;
      &-disabled {
        background-color: transparent !important;
        border: grey 1px solid !important;
        gap: 0.35rem;
        p {
          color: gray;
        }
      }
    }
  }
  &-button-success {
    margin-top: 2rem;
    display: flex;
    width: 99%;
    justify-content: center;
    row-gap: 2rem;
    &-btn {
      width: 14.18rem !important;
    }
  }
}
.clientDetailsCard {
  height: auto;
  border-radius: 1rem;
  margin-top: 1rem;

  h3 {
    font-size: var(--lg-font-size);
    font-weight: 500;
    margin-bottom: 1rem;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .card {
    padding-block: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 1.25rem;
    height: 100%;
    padding-inline: 2rem;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
      padding-inline: 1rem;
    }
  }

  .clientInfo {
    display: flex;
    align-items: center;
    height: 80%;

    .avatar {
      width: 4.9375rem;
      height: 4.9375rem;
      border-radius: 50%;
      margin-right: 2rem;
    }

    .nameAndPosition {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      gap: 0.3rem;
      h4 {
        margin: 0;
        font-size: 1.1rem;
        font-weight: 600;
      }

      p {
        margin: 0;
        font-size: 0.9rem;
        color: #666;
      }
    }
  }

  .contactInfo {
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: space-around;

    @media screen and (max-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      column-gap: 2rem;
    }
    .contactItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;

      img {
        width: 16px;
        margin-right: 0.5rem;
      }

      p {
        font-size: 0.9rem;
        color: #333;
      }
    }
  }
}
.filterContainer {
  position: relative;
}
.filterButton {
  background: none;
  border: none;
  cursor: pointer;

  .dropdownMenu {
    position: absolute;
    top: 3rem;
    right: 3rem;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 180px;
    z-index: 10;

    .dropdownItem {
      padding: 12px;
      font-size: 14px;
      cursor: pointer;
      color: #333;
      font-weight: 400;

      &:hover {
        background-color: #f5f5f5;
      }

      &:first-of-type {
        // border-bottom: 1px solid #e0e0e0;
      }
    }
  }
}
.filterContainer {
  position: relative;
  &-dropdownMenu {
    position: absolute;
    top: 3rem;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 180px;
    z-index: 10;
  }
  &-dropdownItem {
    padding: 12px;
    font-size: 14px;
    cursor: pointer;
    color: #333;
    font-weight: 400;

    &:hover {
      background-color: #f5f5f5;
    }

    &:first-of-type {
      // border-bottom: 1px solid #e0e0e0;
    }
  }
}
@media (max-width: 768px) {
  .shipment-info {
    // padding-inline-start: 1rem;
  }
}
.customDialogPaper {
  width: 100vw !important; /* Full width of the viewport */
  height: 70vh !important; /* 70% of the viewport height */
  position: absolute !important; /* Fix position to ensure it covers the specified area */
  bottom: 0; /* Align the modal to the bottom */
  left: 0;
  margin: 0 !important; /* Remove default margins */
  border-radius: 15px 15px 0 0 !important;
}

.customDialogRoot {
  padding: 0; /* Optional: Remove any additional padding */
}
.card {
  width: 100%;
  border: 1px solid #e2e3e3;
  border-radius: 10px;
  background-color: white;

  &-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.1rem 1.75rem;
    &-data {
      display: flex;
      column-gap: 2rem;
      @media screen and (max-width: 768px) {
        display: none;
      }
      > p {
        color: #808080;
        > span {
          color: #4d5354;
          font-weight: 500;
        }
      }
    }

    &-bottom {
      border-bottom: 1px solid #e2e3e3;
    }
    &-top {
      border-top: 1px solid #e2e3e3;
      padding: 0.8rem 1.75rem;
      > button {
        min-width: fit-content;
        width: 15%;
        margin-inline-start: auto;
      }
    }

    > p {
      color: #4d5354;
      font-size: var(--md-font-size);
    }

    &-time {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;

      @media screen and (max-width: 768px) {
        display: none;
      }

      &-res {
        display: none;
        @media screen and (max-width: 768px) {
          display: flex;
        }
      }

      > p {
        color: #808b8c;
        font-size: var(--md-font-size);
      }
    }

    &-status {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;

      > p {
        width: 7rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.5rem;
        text-transform: capitalize;
        font-size: var(--md-font-size);
      }
      &-accepted {
        background-color: #92ffaf66;
        border: 1px solid #77a18266;
        color: #345a3e;
      }
      &-rejected {
        background-color: #ffaca5;
        border: 1px solid #bd3c3166;
        color: #7d1d1d;
      }
      &-pending {
        background-color: #fff8e1;
        border: 1px solid #ffb300;
        color: #a18309;
      }
      &-active {
        background-color: #caecfe;
        border: 1px solid #06329d66;
        color: #06329d;
      }

      > button {
        padding: 0;
      }
    }
  }
  &-shipping-row {
    padding: 3.1rem 1.75rem;
    display: flex;
    align-items: flex-start;
    gap: 32px;

    // @media screen and (max-width: 768px) {
    //   padding: 2rem 0.5rem;
    // }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 30px;
      // flex: 1;
      &-origin,
      &-destination {
        img {
          opacity: 0.6;
        }
      }
      &:first-of-type {
        align-items: flex-start;
      }
      &:last-of-type {
        align-items: flex-end;
      }

      @media screen and (max-width: 1024px) {
        &-origin,
        &-destination {
          display: none;
        }
        &-pol {
          align-items: flex-start;
        }
        &-pod {
          align-items: flex-end;
        }
      }
      img {
        object-fit: contain;
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 0.3rem;
      }
      &-strong {
        // margin-top: 0.3rem;
        font-weight: 600;
        min-width: max-content;
        color: #1a202c;
        text-wrap: nowrap;
        margin-bottom: 0.3rem;
      }
      &-country {
        // margin-bottom: 0.3rem;
        color: #808080;
        text-wrap: nowrap;
      }
      &-readiness {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        // margin-top: auto;
        > p {
          font-weight: 400;
          color: #808080;

          > span {
            font-weight: 500;
            color: #4d5354;
          }
        }
      }
    }
    &-separator-container {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      position: relative;

      &-origin,
      &-destination {
        & img {
          opacity: 0.6;
        }
      }

      @media screen and (max-width: 1024px) {
        &-origin,
        &-destination {
          display: none;
        }
      }

      & img {
        object-fit: contain;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
      }
    }
    &-separator {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
      padding-bottom: 1.5rem;
      background-image: linear-gradient(
        to right,
        #4d5354 60%,
        rgba(77, 83, 84, 0) 0%
      );
      background-position: 0% 98%;
      background-size: 15px 2px;
      background-repeat: repeat-x;
      opacity: 0.6;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    &-blue-separator {
      @media screen and (max-width: 1024px) {
        display: flex;
      }
      background-image: linear-gradient(
        to right,
        #157ffb 60%,
        rgba(21, 127, 251, 0) 0%
      );
      > p {
        color: #157ffb;
      }
    }
  }
  &-shipping-row-date {
    padding: 0rem 4rem 1rem 4rem;
  }
  &-collapse-res {
    display: none;
    padding: 0;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  &-collapse {
    padding: 0rem 4rem 2rem 4rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &-step {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;

      > h3 {
        font-size: var(--lg-font-size);
        font-weight: 500;
        color: #0d0e0e;
        @media screen and (max-width: 768px) {
          width: 100%;
          border-bottom: 0.6px solid #e3e3e3 !important;
          // padding: 0 0 0.5rem 1.75rem;
        }
      }

      &-data {
        display: flex;
        align-items: flex-start;
        column-gap: 6rem;
        row-gap: 1rem;
        flex-wrap: wrap;

        @media screen and (max-width: 768px) {
          padding: 1rem 1.75rem;
          column-gap: 1.75rem;
          justify-content: space-between;
        }

        &-col {
          flex-direction: column;
          row-gap: 1rem;
          align-items: flex-start;
        }

        &-item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          &-label {
            font-size: 1.1rem;
            color: #71838e;
          }
          &-value {
            font-size: 1.1rem;
            color: #434b5a;
            font-weight: 500;
          }
        }
      }
      &-selected {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        @media screen and (max-width: 768px) {
          padding-inline-start: 5rem;
          padding-bottom: 1rem;
        }
        > p {
          color: #0d0e0e;
        }
      }
    }

    &-separator {
      height: 1px;
      width: 100%;
      background-color: #e9e9e9;
    }
  }
}

// Carousel edits for responsive view
.carouselArrows {
  inset-inline-end: 0rem;
}
@media screen and (max-width: 376px) {
  .card {
    &-row {
      &-time {
        margin-inline-end: 1rem;
      }
    }
  }
}
