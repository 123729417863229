.card {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  padding: 24px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(204, 204, 204, 0.5);
  hr {
    border: none;
    outline: none;
    height: 1px;
    background-color: #dedcdc;
    margin-block: 1.5rem;
    width: 100%;
    min-width: 800px;
  }

  &-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 800px;
  }
}
