.applyButton {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #157ffb; /* The main blue color for the border */
  border-radius: 25px; /* Rounded edges */
  background-color: transparent; /* Transparent background */
  color: #157ffb; /* Blue color for the text */
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // margin-left: 2rem;
  align-self: center;
  width: 7rem;

  @media screen and (max-width: 650px) {
    margin-top: 0;
  }
  // &:hover {
  //   background-color: #157ffb; /* Blue background on hover */
  //   color: white; /* White text on hover */
  // }

  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.3;
  }
}
.redApplyButton {
  background-color: transparent;
  color: #c44343; // The red color for the text and border
  border: 2px solid #c44343; // Red border
  padding: 10px 20px;
  border-radius: 50px; // Gives the round corners
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f5e4e4; // Slight background color on hover
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(196, 67, 67, 0.3); // Focus outline effect
  }
}
