.sales-requests {
  width: 100%;
  height: 130%;
  padding-inline-start: calc(var(--sidebar-width) + 3.5rem);
  padding-inline-end: 3rem;
  padding-top: calc(2.5rem + 95px);
  overflow-x: scroll;
  scrollbar-width: none;
  margin-bottom: 1rem;
  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    // margin-bottom: 1rem;=
  }
  &-title {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.75rem;
    color: #1b2029;
    padding-bottom: 2rem;
  }
  &-tabs {
    width: 100%;
    min-width: max-content;
  }
  &-empty {
    width: 100%;
    height: 100vh;
    display: flex;
    padding-inline-start: calc(var(--padding-x) * 1.15);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    gap: 2.4rem;
    text-align: center;
    h2 {
      font-weight: 500;
      font-size: 1.625rem;
    }
    P {
      color: #667085;
    }
  }
  &-success-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    // img {
    //   margin-block: 1rem;
    // }
  }
  &-h3 {
    // margin-top: 3rem;
    padding-inline-start: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    margin-block: 1rem;
  }
  &-h6 {
    // margin-top: 1rem;
    padding-inline-start: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: center;

    // margin-bottom: 1rem;
  }
  &-button {
    margin-top: 2rem;
    display: flex;
    width: 99%;
    justify-content: flex-end;
    &-btn {
      width: 14.18rem !important;
      &-disabled {
        background-color: transparent !important;
        border: grey 1px solid !important;
        gap: 0.35rem;
        p {
          color: gray;
        }
      }
    }
  }
  &-button-success {
    margin-top: 1rem;
    display: flex;
    width: 99%;
    justify-content: center;
    row-gap: 2rem;
    &-btn {
      width: 14.18rem !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .sales-requests {
    padding-inline: 1.5rem;
    padding-top: 95px;

    &-empty {
      padding: 1rem;
    }
  }
}

.empty-status {
  margin-top: 6rem;
}
